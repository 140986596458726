// 公共模块API，包含登录，菜单，登出的接口

import request from '@/utils/request'

const commonApi = {
  // 用户登录
  userLogin: (data) => {
    return request.init({
      url: '/application/oa/system/user/login.htm',
      params: data,
    })
  },

  // 用户登出
  userLogout: (data) => {
    return request.init({
      url: '/application/oa/system/user/logout.htm',
      data,
    })
  },

  // 登录页，刷新验证码，这里不能用post或get请求，会被网关拦截
  refreshCode: () => {
    return '/application/oa/system/user/imgCode/generate.htm'
  },

  // 获取菜单数据
  queryMenuList: (data) => {
    return request.init({
      url: '/application/oa/modules/manage/system/roleMenu/find.htm',
      method: 'get',
      params: data,
    })
  },

  // 司机招募 查询城市列表
  queryCityList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/geo/twoLevelTree',
      data,
    })
  },

  // 司机招募 查询车型车长
  queryCarTypeList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/vehicleBase/getVehicleCodeTree',
      data,
    })
  },

  // 司机招募 查询车型车长三级
  queryVehicleCategoryTree: (data) => {
    return request.init({
      url: '/common/dict/getVehicleCategoryTree',
      data,
    })
  },

  // 司机招募 查询来源渠道
  queryChannelList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/applicationSurveyChannel/list',
      data,
    })
  },

  // 司机招募 查询申请状态
  queryApplyStatusList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/driverApplication/applicationStatusList',
      data,
    })
  },

  // 司机招募 培训状态
  queryTrainStatusList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/driverApplication/trainingStatusList',
      data,
    })
  },

  // 司机招募 司机一级标签
  queryDriverFristLabelList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/driverApplication/firstLevelDriverLabelTree',
      data,
    })
  },

  // 司机招募 司机标签
  queryDriverLabelList: (data) => {
    return request.init({
      url: '/application/oa/common/dict/driverApplication/driverLabelTree',
      data,
    })
  },

  // 身份证OCR
  verifyOcrIdCard: (data) => {
    return request.init({
      url: '/application/oa/common/ocr/idCard',
      data,
    })
  },
  // 驾驶证ocr
  verifyOcrDrivingLicense: (data) => {
    return request.init({
      url: '/application/oa/common/ocr/drivingLicense',
      data,
    })
  },
  // 行驶证ocr
  verifyOcrVehicleLicense: (data) => {
    return request.init({
      url: '/application/oa/common/ocr/vehicleLicense',
      data,
    })
  },
  // 从业资格证ocr
  verifyOcrTransportBusinessLicense: (data) => {
    return request.init({
      url: '/application/oa/common/ocr/transportBusinessLicense',
      data,
    })
  },
  // 道路运输许可证ocr
  verifyOcrRoadTransportCertificate: (data) => {
    return request.init({
      url: '/application/oa/common/ocr/roadTransportCertificate',
      data,
    })
  },
  // 添加司机
  importDriverApi: (data) => {
    return request.init({
      url: '/application/oa/driverApplication/importDriver',
      data,
    })
  },
}

export default commonApi
