import commonApi from '@/api/geoFence/common'

export default {
  namespaced: true,

  state: {
    cityDataList: [], // 三级城市
    secondaryCities: [], // 二级城市
  },

  mutations: {
    get_city_data_list(state, data) {
      state.cityDataList = data
    },

    get_secondary_cities_list(state, data) {
      state.secondaryCities = data
    },
  },

  actions: {
    getCityDataList({ commit, state }) {
      if (state.cityDataList.length == 0) {
        commonApi.queryCityTree().then((res) => {
          // console.log('ss', res)
          if (res.code == 0) {
            commit('get_city_data_list', res.data)
          }
        })
      }
    },

    getSecondaryCitiesList({ commit, state }) {
      if (state.secondaryCities.length == 0) {
        commonApi.queryCityTree().then((res) => {
          // console.log('ss', res)
          if (res.code == 0) {
            for (let i = 0; i < res.data.length; i++) {
              let children = res.data[i].children
              for (let j = 0; j < children.length; j++) {
                delete res.data[i].children[j].children
              }
            }
            commit('get_secondary_cities_list', res.data)
          }
        })
      }
    },
  },
}
