export default [
  // 车辆管理
  {
    path: '/user-center/vehicle-management/list',
    name: 'vehicleManagement',
    component: () =>
      import('@/views/userCenter/vehicleManagement/list/index.vue'),
    meta: { title: '车辆管理列表', keepAlive: true },
  },
  {
    path: '/user-center/vehicle-management/list/detail',
    name: 'detail',
    component: () =>
      import('@/views/userCenter/vehicleManagement/detail/index.vue'),
    meta: { title: '车辆详情' },
  },
  {
    path: '/user-center/vehicle-management/list/record-details',
    name: 'detail',
    component: () =>
      import(
        '@/views/userCenter/vehicleManagement/detail/tab/recordDetails.vue'
      ),
    meta: { title: '车辆信息变更记录' },
  },
  {
    path: '/user-center/vehicle-management/list/documentInfo',
    name: 'documentInfo',
    component: () =>
      import(
        '@/views/userCenter/vehicleManagement/detail/tab/documentInfo.vue'
      ),
    meta: { title: '证件详情' },
  },
]
