// 发票管理
export default [
  {
    path: '/finance/receipt/electronicInvoice',
    name: 'electronicInvoice',
    component: () =>
      import('@/views/finance/receipt/electronicInvoice/list/index.vue'),
    meta: { title: '发票管理', keepAlive: true },
  },
  {
    path: '/finance/receipt/electronicInvoice/dataImport',
    name: 'mailInvoice',
    component: () =>
      import('@/views/finance/receipt/electronicInvoice/detail/dataImport.vue'),
    meta: { title: '数据导入' },
  },
  {
    path: '/finance/receipt/electronicInvoice/uploadFile',
    name: 'uploadFile',
    component: () =>
      import('@/views/finance/receipt/electronicInvoice/detail/uploadFile.vue'),
    meta: { title: '上传文件' },
  },
]
