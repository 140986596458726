import request from '@/utils/request'

const commonApi = {
  // 城市数据
  getAddressThreeLevelToTree: (data) => {
    return request.init({
      url: '/common/dict/getAddressThreeLevelToTree',
      data,
    })
  },

  // 城市订单车型
  getCityOrderVehicleByCitysApi: (data) => {
    return request.init({
      url: '/orderMatch/oa/orderVehicle/getCityOrderVehicleByCitys',
      data,
    })
  },

  // 开通城市列表
  queryOpenCityList: (data) => {
    return request.init({
      url: '/common/openCity/openCityList',
      data,
    })
  },
}

export default commonApi
