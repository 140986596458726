import { asyncRouterMap, constantRouterMap } from '@/router'
import { deepClone } from '@/utils'

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission (route, roles) {
  var index = -1
  if (route && route.name) {
    for (var i in roles) {
      if (roles[i].label == route.name) {
        if (roles[i].children.length > 0 && route.children.length > 0) {
          const childList = []
          roles[i].children.forEach(element => {
            route.children.forEach(item => {
              if (element.label === item.name) {
                childList.push(item)
              }
            })
          })
          route.children = childList
        }
        return route
      }
    }
  }
  return index
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter (asyncRouterMap, roles) {
  const res = []
  const accessedRouters = asyncRouterMap.filter(route => {
    const menu = hasPermission(route, roles)
    if (menu != -1) {
      res.push(menu)
    }
    // return false
  })
  return res
}

const permission = {
  state: {
    routers: [],
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      routers.sort(function (a, b) {
        // 按照时间降
        return a.orderNum - b.orderNum
      })
      for (const i in routers) {
        routers[i].children.sort(function (a, b) {
          return a.orderNum - b.orderNum
        })
      }
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const asyncRouterMapCopy = deepClone(asyncRouterMap) // 深拷贝避免本地路由表被递归时覆盖，导致切换机构缺少路由
        const accessedRouters = filterAsyncRouter(asyncRouterMapCopy, data)
        commit('SET_ROUTERS', accessedRouters)
        resolve(accessedRouters)
      })
    }
  }
}

export default permission
