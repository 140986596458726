export default [
  {
    path: '/enterprise-operations/corporate-account/enterprise-certification',
    name: 'enterpriseCertification',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseCertification/index.vue'
      ),
    meta: { title: '企业认证' },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-certification/info-examine',
    name: 'infoExamine',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseCertification/infoExamine.vue'
      ),
    meta: { title: '认证详情' },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-certification/account-examine',
    name: 'accountExamine',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseCertification/accountExamine.vue'
      ),
    meta: { title: '认证详情' },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-certification/admin-examine',
    name: 'adminExamine',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseCertification/adminExamine.vue'
      ),
    meta: { title: '认证详情' },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-list',
    name: 'enterpriseList',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseList/index.vue'
      ),
    meta: { title: '企业列表', keepAlive: true },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-list/details',
    name: 'enterpriseListDetails',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseList/details.vue'
      ),
    meta: { title: '企业详情', keepAlive: true },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-list/employee-details',
    name: 'employeeDetails',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseList/compontents/employee/employeeDetails.vue'
      ),
    meta: { title: '员工详情' },
  },

  // 企业提现申请
  {
    path: '/enterprise-operations/corporate-account/enterprise-list/withdrawalApply',
    name: 'withdrawalApply',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseList/compontents/wallet/detail/withdrawalApply.vue'
      ),
    meta: { title: '提现申请' },
  },
  {
    path: '/enterprise-operations/corporate-account/enterprise-list/withdrawal-record/detail',
    name: 'withdrawalDetail',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseList/compontents/withdrawalRecord/detail/withdrawalDetails.vue'
      ),
    meta: { title: '提现记录详情' },
  },

  // 代收协议认证
  {
    path: '/enterprise-operations/corporate-account/payment-certification',
    name: 'paymentCertificationList',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/paymentCertification/list/index.vue'
      ),
    meta: { title: '代收协议认证列表', keepAlive: true },
  },
  {
    path: '/enterprise-operations/corporate-account/payment-certification/details',
    name: 'paymentCertification',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/paymentCertification/details/index.vue'
      ),
    meta: { title: '代收协议认证' },
  },

  {
    path: '/enterprise-operations/corporate-account/enterprise-list/property/payee/details',
    name: 'paymentCertificationDetails',
    component: () =>
      import(
        '@/views/enterpriseOperations/corporateAccount/enterpriseList/compontents/property/payee/details.vue'
      ),
    meta: { title: '代收协议详情' },
  },

]
