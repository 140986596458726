/**
 * 引导区列表
 */
import guideAreaApi from '@/api/driverOperation/guideArea'

const state = {
  guideAreaList: [], // 引导区列表
  cityId: 0, // 搜索城市
  creator: '', // 创建人
  keyWord: '', // 搜索关键词
  areaStatus: 0, // 搜索引导区状态
  createDate: [], // 创建时间
  currentPage: 1, // 当前页
  pageSize: 10, // 每页多少数据
  total: 0, // 引导区搜索结果总数
  loading: false, // 搜索请求中状态
}

const mutations = {
  setGuideAreaList(state, list) {
    state.guideAreaList = list
  },
  setTotal(state, total) {
    state.total = total
  },
  setCityId(state, cityId) {
    state.cityId = cityId
  },
  setCreator(state, creator) {
    state.creator = creator
  },
  setKeyWord(state, keyWord) {
    state.keyWord = keyWord
  },
  setAreaStatus(state, areaStatus) {
    state.areaStatus = areaStatus
  },
  setCreateDate(state, createDate) {
    state.createDate = createDate
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },
  setPageSize(state, pageSize) {
    state.pageSize = pageSize
  },
  setLoading(state, loading) {
    state.loading = loading
  },
}

const actions = {
  /**
   * 搜索引导区,用于列表
   * @param cityId String 城市id
   * @param validTime [Date,Date] 有效时间
   */
  async getGuideArea({ state, commit }) {
    let timer = setTimeout(() => {
      commit('setGuideAreaList', [])
      commit('setLoading', true)
    }, 500)
    try {
      let ret = await guideAreaApi.searchGuideArea({
        cityId: state.cityId,
        keyWord: state.keyWord,
        areaStatus: state.areaStatus,
        creator: state.creator,
        createTime: state.createDate.map(formatDateTime),
        page: state.currentPage,
        perPage: state.pageSize,
      })
      clearTimeout(timer)
      commit('setLoading', false)
      commit('setGuideAreaList', ret.list)
      commit('setTotal', ret.total)
      commit('setCurrentPage', ret.current_page)
    } catch (err) {
      commit('setLoading', false)
      throw err
    }
  },
  /**
   * 设置引导状态
   * @param areaCode 引导区编号
   * @param areaStatus 引导区状态
   */
  async setAreaStatus({}, { areaCode, areaStatus }) {
    return await guideAreaApi.editGuideArea(areaCode, {
      guide_area_code: areaCode,
      area_status: areaStatus,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

/**
 * 格式化日期时间
 * @param date date对象
 * @returns {string} 格式化的字符串
 */
function formatDateTime(date) {
  let y = fill(date.getFullYear(), 4)
  let m = fill(date.getMonth() + 1, 2)
  let d = fill(date.getDate(), 2)
  let h = fill(date.getHours(), 2)
  let min = fill(date.getMinutes(), 2)
  let s = fill(date.getSeconds(), 2)
  return `${y}-${m}-${d} ${h}:${min}:${s}`
}

/**
 * 补0，并转为字符串
 * @param number 数字
 * @param length 长度
 * @returns {string} 补0后的字符串
 */
function fill(number, length) {
  return number.toString().padStart(length, '0')
}
