export default [
  {
    path: '/driver-operation/activity/driver-activity',
    name: 'activityPlan',
    component: () =>
      import(
        '@/views/driverOperation/driveractivity/activityplan/list/index.vue'
      ),
    meta: { title: '司机活动计划', keepAlive: true },
  },
  {
    path: '/driver-operation/activity/driver-activity/created',
    name: 'activityCreated',
    hidden: true,
    component: () =>
      import(
        '@/views/driverOperation/driveractivity/activityplan/detail/created'
      ),
    meta: {
      title: '新建司机活动计划',
      activeMenu: '/driveractivity/activityplan',
    },
  },
  {
    path: '/driver-operation/activity/driver-activity/details',
    name: 'activityDetails',
    hidden: true,
    component: () =>
      import(
        '@/views/driverOperation/driveractivity/activityplan/detail/index'
      ),
    meta: {
      title: '司机活动计划详情',
      activeMenu: '/driveractivity/activityplan',
    },
  },
  {
    path: '/driver-operation/activity/distribution-to-driver',
    name: 'eventDistribution',
    component: () =>
      import(
        '@/views/driverOperation/driveractivity/eventdistribution/list/index.vue'
      ),
    meta: { title: '司机分发活动' },
  },
  {
    path: '/driver-operation/activity/driver-token',
    name: 'exchangePassword',
    component: () =>
      import(
        '@/views/driverOperation/driveractivity/exchangepassword/list/index.vue'
      ),
    meta: { title: '司机兑换口令' },
  },
]
