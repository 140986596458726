const state = {
    form: {
        city_type: '50', // 	城市类型:50=全国,100=城市,200=区县
        city_id: '', // 市id
        area_id: '', // 区id
        car_name: '', // 车型名称
        vehicle_type: 100, // 车型分类：100小车，200大车
        vehicle_details_img: '',
        vehicle_details_3d_img: '',
        base_vehicle: '', // 基础车型 逗号分割
        specification_sure: '', // 特殊规格必选 逗号分隔
        specification_not_sure: '', // 特殊规格可选 逗号分隔
        push_templates: '', // 推单模版code
        push_order_type: '100', // 推单类型:100=直线推单,200=导航距离
        order_product_type: [], // 业务类型
        people_price_mode_code: [], // 个人业务类型
        enterprise_price_mode_code: [], // 业务类型
        vehicle_thumbnail: '', // 车型缩略图
        vehicle_img: '', // 车型大图
        vehicle_specification_compression_img: {}, // 车辆规格缩略图
        vehicle_specification_img: {}, // 车辆规格大图
        vehicle_specification_3d_img: {}, // 车辆规格3d大图
        min_car_length:	undefined, // 最小车长,
        max_car_length:	undefined, // 最大车长,
        min_car_width:	undefined, // 最小车宽,
        max_car_width:	undefined, // 最大车宽,
        min_car_height:	undefined, // 最小车高,
        max_car_height:	undefined, // 最大车高,
        min_car_xc:	undefined, // 最小车厢长,
        max_car_xc:	undefined, // 最大车厢长,
        min_car_xk:	undefined, // 最小车厢宽,
        max_car_xk:	undefined, // 最大车厢宽,
        min_car_xg:	undefined, // 最小车厢高,
        max_car_xg:	undefined, // 最大车厢高,
        min_car_carrier:	undefined, // 最小载方,
        max_car_carrier:	undefined, // 最大载方,
        min_car_load:	undefined, // 最小载重,
        max_car_load:	undefined, // 最大载重,
        vehicle_describe_file:	'', // 描述文件,
    },
    specificationsList: []
}

const mutations = {
  SET_FORM: (state, data) => {
    // eslint-disable-next-line no-prototype-builtins
    state.form = data
  },
  INIT_FORM: (state, data) => {
    state.form =  {
      city_type: '50', // 	城市类型:50=全国,100=城市,200=区县
      city_id: '', // 市id
      area_id: '', // 区id
      car_name: '', // 车型名称
      vehicle_type: 100, // 车型分类：100小车，200大车
      order_product_type: [], // 业务类型
      people_price_mode_code: [], // 个人业务类型
      enterprise_price_mode_code: [], // 业务类型
      vehicle_details_img: '',
      vehicle_details_3d_img: '',
      base_vehicle: '', // 基础车型 逗号分割
      specification_sure: '', // 特殊规格必选 逗号分隔
      specification_not_sure: '', // 特殊规格可选 逗号分隔
      push_templates: '', // 推单模版code
      push_order_type: '100', // 推单类型:100=直线推单,200=导航距离
      vehicle_thumbnail: '', // 车型缩略图
      vehicle_img: '', // 车型大图
      vehicle_specification_compression_img: {}, // 车辆规格缩略图
      vehicle_specification_img: {}, // 车辆规格大图
      vehicle_specification_3d_img: {}, // 车辆规格3d大图
      min_car_length:	undefined, // 最小车长,
      max_car_length:	undefined, // 最大车长,
      min_car_width:	undefined, // 最小车宽,
      max_car_width:	undefined, // 最大车宽,
      min_car_height:	undefined, // 最小车高,
      max_car_height:	undefined, // 最大车高,
      min_car_xc:	undefined, // 最小车厢长,
      max_car_xc:	undefined, // 最大车厢长,
      min_car_xk:	undefined, // 最小车厢宽,
      max_car_xk:	undefined, // 最大车厢宽,
      min_car_xg:	undefined, // 最小车厢高,
      max_car_xg:	undefined, // 最大车厢高,
      min_car_carrier:	undefined, // 最小载方,
      max_car_carrier:	undefined, // 最大载方,
      min_car_load:	undefined, // 最小载重,
      max_car_load:	undefined, // 最大载重,
      vehicle_describe_file:	undefined, // 描述文件,
    }
  },
  SET_SPECIFICATIONS_LIST: (state, data) => {
    state.specificationsList = data
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

