export default [
  // 司机资料审核
  {
    path: '/user-center/vehicle-audit/list',
    name: 'vehicleAuditList',
    component: () => import('@/views/userCenter/vehicleAudit/list/index'),
  },
  // 司机资料详情
  {
    path: '/user-center/vehicle-audit/detail',
    name: 'vehicleAuditDetail',
    component: () => import('@/views/userCenter/vehicleAudit/detail/index'),
  },
]
