export default [
  {
    path: '/enterprise-operations/enterprise-project/collect-drivers',
    name: 'collectDrivers',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseProject/collectDrivers/list'
      ),
    meta: { title: '收藏司机', keepAlive: true },
  },
  {
    path: '/enterprise-operations/enterprise-project/collect-drivers/data-import',
    name: 'dataImport',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseProject/collectDrivers/detail/dataImport'
      ),
    meta: { title: '导入收藏司机' },
  },
]
