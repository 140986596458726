export default [
  {
    path: '/enterprise-operations/enterprise-project/project-management',
    name: 'projectManagement',
    component: () => import('@/views/enterpriseOperations/enterpriseProject/projectManagement/index.vue'),
    meta: { title: '项目管理' },
  },
  {
    path: '/enterprise-operations/enterprise-project/project-management/details',
    name: 'projectManagementDetails',
    component: () => import('@/views/enterpriseOperations/enterpriseProject/projectManagement/details.vue'),
    meta: { title: '项目管理详情', keepAlive: true },
  },
  {
    path: '/enterprise-operations/enterprise-project/project-management/creat',
    name: 'projectManagementCreat',
    component: () => import('@/views/enterpriseOperations/enterpriseProject/projectManagement/creat.vue'),
    meta: { title: '新建项目管理' },
  },
  {
    path: '/enterprise-operations/enterprise-project/project-configuration',
    name: 'projectConfiguration',
    component: () => import('@/views/enterpriseOperations/enterpriseProject/projectConfiguration/index.vue'),
    meta: { title: '项目配置' },
  },
  {
    path: '/enterprise-operations/enterprise-project/project-configuration/creat',
    name: 'projectConfigurationCreat',
    component: () => import('@/views/enterpriseOperations/enterpriseProject/projectConfiguration/creat.vue'),
    meta: { title: '新建项目配置' },
  },
]
