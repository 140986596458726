import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

// 缓存菜单id
let menuId = 1

/**
 * 设置菜单id
 * @param newMenuId
 */
function setMenuId(newMenuId) {
  menuId = newMenuId
}

// 跳转到登录页的去重定时器
let gotoLoginTimer = 0

/**
 * 跳转到登录页
 */
function gotoLogin() {
  clearTimeout(gotoLoginTimer)
  gotoLoginTimer = setTimeout(() => {
    window.localStorage.removeItem('api_token')
    if (router.currentRoute.path != '/login') {
      // router.push({ path: '/login', query: { ref: router.currentRoute.path } })

      // console.log('ss', window.location.href)
      // console.log('aa', window.location.host)

      let ref = window.location.href.split(window.location.host)
      // console.log(ref[ref.length - 1])
      router.push({ path: '/login', query: { ref: ref[ref.length - 1] } })
    }
  }, 100)
}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30 * 1000, // request timeout
  method: 'post',
  headers: { menu_id: menuId },
  withCredentials: false,
})
// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers.token = window.localStorage.getItem('api_token')
    config.headers.menu_id = menuId
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    // 如果是导出数据，直接返回
    if (response.headers['content-type'] === 'text/csv') {
      return response
    }
    const res = response.data
    // 登录过期
    if (res.code === 1001) {
      gotoLogin()
      // Message.error(res.msg)
      // return Promise.reject(new Error(res.msg || '登录过期'))
      return res
    }
    // 请求错误
    if (res.code !== 0) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(res)
    } else {
      return res
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default {
  init: service,
  setMenuId: setMenuId,
}
