const order = {
  namespaced: true,

  state: {
    city_order_vehicle_id: '',
    order_vehicle_ids: [],
    city_detail: [],
    car_order_name: '',
    car_order_detail: {},
  },

  mutations: {
    SET_CITY_ORDER_VEHICLE_ID: (state, id) => {
      state.city_order_vehicle_id = id
    },
    SET_ORDER_VEHICLE_IDS: (state, ids) => {
      state.order_vehicle_ids = ids
    },
    SET_CITY_DETAIL: (state, city_detail) => {
      state.city_detail = city_detail
    },
    SET_CAR_ORDER_NAME: (state, name) => {
      state.car_order_name = name
    },
    SET_CAR_ORDER_DETAIL: (state, detail) => {
      state.car_order_detail = detail
    },
  },

  actions: {
    setCityOrderVehicleId({ commit }, id) {
      commit('SET_CITY_ORDER_VEHICLE_ID', id)
    },
    setOrderVehicleIds({ commit }, ids) {
      commit('SET_ORDER_VEHICLE_IDS', ids)
    },
    setCityDetail({ commit }, city) {
      commit('SET_CITY_DETAIL', city)
    },
    setCarOrderName({ commit }, name) {
      commit('SET_CAR_ORDER_NAME', name)
    },
    setCarOrderDetail({ commit }, detail) {
      commit('SET_CAR_ORDER_DETAIL', detail)
    },
  },
}

export default order
