export default [
  // 企业电子合同
  {
    path: '/user-center/contract-management/enterprise-contract/list',
    name: 'enterpriseContract',
    component: () => import('@/views/userCenter/contractManagement/enterpriseContract/index.vue'),
  },
  // 司机电子合同
  {
    path: '/user-center/contract-management/driver-contract/list',
    name: 'driverContract',
    component: () => import('@/views/userCenter/contractManagement/driverContract/index.vue'),
  },
]
