export default [
    {
      path: '/configure/city/city-configuration',
      name: 'cityConfiguration',
      component: () => import('@/views/configure/cityConfiguration/index.vue'),
      meta: { title: '城市配置' },
    },
    {
      path: '/configure/city/model-configuration',
      name: 'modelConfiguration',
      component: () => import('@/views/configure/modelConfiguration/list/index.vue'),
      meta: { title: '城市订单车型配置' },
    },
    {
      path: '/configure/city/model-configuration/creat',
      name: 'modelConfigurationCreat',
      component: () => import('@/views/configure/modelConfiguration/creat/index.vue'),
      meta: { title: '城市订单车型配置新增' },
    },
    {
      path: '/configure/city/model-configuration/details',
      name: 'modelConfigurationDetails',
      component: () => import('@/views/configure/modelConfiguration/creat/index.vue'),
      meta: { title: '城市订单车型配置编辑' },
    },
    {
      path: '/configure/city/price-configuration',
      name: 'priceConfiguration',
      component: () => import('@/views/configure/priceConfiguration/list/index.vue'),
      meta: { title: '服务及价格配置' },
    },
    {
      path: '/configure/city/price-configuration/creat',
      name: 'priceConfigurationCreat',
      component: () => import('@/views/configure/priceConfiguration/creat/index.vue'),
      meta: { title: '服务及价格配置新增' },
    },
    {
      path: '/configure/city/price-configuration/edit',
      name: 'priceConfigurationEdit',
      component: () => import('@/views/configure/priceConfiguration/creat/index.vue'),
      meta: { title: '服务及价格配置新增' },
    },
    {
      path: '/configure/city/price-configuration/details',
      name: 'priceConfigurationDetails',
      component: () => import('@/views/configure/priceConfiguration/creat/index.vue'),
      meta: { title: '服务及价格配置详情' },
    },
    {
      path: '/configure/city/model-group',
      name: 'modelGroupList',
      component: () => import('@/views/configure/modelGroup/list/index.vue'),
      meta: { title: '城市车型组' },
    },
    {
      path: '/configure/city/model-group/creat',
      name: 'modelGroupCreat',
      component: () => import('@/views/configure/modelGroup/creat/index.vue'),
      meta: { title: '新建城市车型组' },
    },
    {
      path: '/configure/city/model-group/edit',
      name: 'modelGroupEdit',
      component: () => import('@/views/configure/modelGroup/creat/index.vue'),
      meta: { title: '新建城市车型组' },
    },
    {
      path: '/configure/city/model-group/price',
      name: 'modelGroupPrice',
      component: () => import('@/views/configure/modelGroup/price/index.vue'),
      meta: { title: '城市车型组管理配置' },
    },
]