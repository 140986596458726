<template>
  <div class="pagination-box">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="pageSizes"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :layout="layout"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pageSizes: {
      type: Array,
      default: function () {
        return [10, 20, 50, 80, 100]
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    layout: {
      type: String,
      default: "total, sizes, ->, prev, pager, next, jumper"
      // default: 'total, sizes, prev, pager, next, jumper'
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    page: {
      get () {
        return this.currentPage
			},
			set (val) {
				this.$emit('update:currentPage', val)
			}
		},
		pageSize: {
			get () {
				return this.limit
			},
			set (val) {
				this.$emit('update:limit', val)
			}
		}
  },
  methods: {
    // 当每页的size改变的时候，回调父组件
    handleSizeChange (val) {
      this.$emit("handleSizeChange", "size", val)
    },
    // 当前页改变的时候，回调父组件
    handleCurrentChange (val) {
      this.$emit("handleCurrentChange", "page", val)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-box {
  padding: 10px 0;
}
</style>
