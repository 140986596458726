// 全局注册组件

import Pagination from '@/components/Pagination' // pagination

const installComponents = {
	install: function (Vue) {
		Vue.component('pagination', Pagination)
	}
}

export default installComponents
