<template>
  <div class="header-wrap flex flex-justify-between flex-align-center">
    <div><img src="../../../../assets/images/layout/logo.png" /></div>
    <div class="user">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ user.fullname }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="toUserInfo">
            <span>{{ user.staff_account }}</span>
          </el-dropdown-item>
          <el-dropdown-item :disabled="true">
            <span class="role-label">
              {{
                user.role_name
                  ? user.role_name.map((item) => item.role_name).join(' ')
                  : ''
              }}
            </span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutHeader',
  computed: {
    user() {
      return this.$store.state.main.userInfo
    },
  },
  methods: {
    toUserInfo() {
      this.$router.push('/user-info')
    },
    logout() {
      this.$store
        .dispatch('main/logout')
        .then(() => {
          this.$router.push('/login')
        })
        .catch((err) => {
          this.$message.error('登出失败:' + err.toString())
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.header-wrap {
  height: 100%;
}
.el-dropdown-link {
  color: #bfcbd9;
  cursor: pointer;
}
.el-dropdown-menu {
  max-width: 200px;
}
.role-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
