const getters = {
  staff_code_list: (state) => state.jurisdiction.staff_code_list,
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  language: (state) => state.app.language,
  roles: (state) => state.user.roles,
  addRouters: (state) => state.permission.addRouters,
  userInfo: (state) => state.user.userInfo,
  permission_routes: (state) => state.permission.routers,
  city_order_vehicle_id: (state) => state.order.city_order_vehicle_id,
  order_vehicle_ids: (state) => state.order.order_vehicle_ids,
  city_detail: (state) => state.order.city_detail,
  car_order_name: (state) => state.order.car_order_name,
  car_order_detail: (state) => state.order.car_order_detail,
  tableData: (state) => state.order.tableData,
  form: (state) => state.modelConfiguration.form,
  specificationsList: (state) => state.modelConfiguration.specificationsList,
  priceForm: (state) => state.priceConfiguration.form,
  optionalConfig: (state) => state.priceConfiguration.optionalConfig,
  organization_uuid: (state) => state.enterpriseList.organization_uuid,
}

export default getters
