export default [
  {
    path: '/config/geo-fence/fence',
    name: 'list',
    component: () => import('@/views/config/geoFence/fence/list/List'),
    meta: { title: '围栏管理', icon: 'el-icon-picture-outline' },
  },
  {
    path: '/config/geo-fence/category',
    name: 'category',
    component: () => import('@/views/config/geoFence/fence/category/Category'),
    meta: { title: '分类管理', icon: 'el-icon-menu' },
  },
]
