import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { toYuan, toFen, toMenoy } from './utils'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss' // global css
import installComponents from '@/utils/install' // 全局注册组件

import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/styles/font.css'

import AMap from 'vue-amap'
Vue.use(AMap)
AMap.initAMapApiLoader({
  key: 'f4dc089576c0de038bbcc73fe293a2c1',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.PolygonEditor',
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
})

// 腾讯滑块验证(新建一个js文件)
import TencentCaptcha from '@/assets/js/TencentCaptcha'
Vue.use(TencentCaptcha)
// 使用
Vue.prototype.$bus = new Vue()

Vue.use(VueQuillEditor)

Vue.use(Element)
// 全局注册组件
Vue.use(installComponents)

Vue.config.productionTip = false

// 全局过滤器
Vue.filter('formatTime', function (val) {
  function padStart(num) {
    return (num | 0).toString().padStart(2, '0')
  }
  return (
    padStart(val / 60 / 60) +
    ':' +
    padStart((val / 60) % 60) +
    ':' +
    padStart(val % 60)
  )
})

Vue.filter('dateFormat', function (dateStr, pattern = '') {
  var dt = new Date(dateStr * 1000)
  var year = dt.getFullYear()
  var month = (dt.getMonth() + 1).toString().padStart(2, 0)
  var day = dt.getDate().toString().padStart(2, 0)

  if (pattern && pattern.toLowerCase() === 'yyy-mm-dd') {
    return `${year}-${month}-${day}`
  } else {
    var hour = dt.getHours().toString().padStart(2, 0)
    var minute = dt.getMinutes().toString().padStart(2, 0)
    var second = dt.getSeconds().toString().padStart(2, 0)
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }
})

// 全局方法

Vue.prototype.toYuan = toYuan
Vue.prototype.toFen = toFen
Vue.prototype.toMenoy = toMenoy

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
