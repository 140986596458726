// 公共模块API，包含登录，菜单，登出的接口

import request from '@/utils/request'

const commonApi = {
  // 城市数据
  queryCityTree: (data) => {
    return request.init({
      url: '/map/oa/fence/common/geo/tree',
      data,
    })
  },
  // 电子围栏分类数据
  queryFenceCategory: (data) => {
    return request.init({
      url: '/map/oa/fence/fenceCategory/selectList',
      data,
    })
  },
}

export default commonApi
