/**
 * Created by PanJiaChen on 16/11/18.
 */
import { Message } from 'element-ui'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * 分格式化元
 * @params {price} number | string - 分
 * @returns {string} 格式化之后的值
 */
export function toYuan(price) {
  return (parseFloat(price) / 100 || 0).toFixed(2)
}
/**
 * 分格式化元，千加逗号，以此类推
 * @params {price} number | string - 分
 * @returns {string} 格式化之后的值
 */
export function toMenoy(price) {
  let menoy = (parseFloat(price) / 100 || 0).toFixed(2)
  var left = menoy.split('.')[0]
  var right = menoy.split('.')[1]
  var len = left.length
  if (len <= 3) {
    return menoy
  } else {
    var r = len % 3
    if (r > 0) {
      left =
        left.slice(0, r) + ',' + left.slice(r, len).match(/\d{3}/g).join(',')
    } else {
      left = left.slice(r, len).match(/\d{3}/g).join(',')
    }
    return left + '.' + right
  }
}
/**
 * 元格式化分
 * @params {price} number | string - 元
 * @returns {number} 格式化之后的值
 */
export function toFen(price) {
  return parseFloat(price) * 100
}
/**
 * 米格式化公里
 * @params {price} number | string - 米
 * @returns {string} 格式化之后的值
 */
export function toKm(value) {
  return (parseFloat(value) / 1000).toFixed(1)
}
/**
 * 公里格式化米
 * @params {value} number | string - 公里
 * @returns {number} 格式化之后的值
 */
export function toMeter(value) {
  return parseFloat(value) * 1000
}
/**
 * 百分比/100
 * @params {value} number | string -
 * @returns {string} 格式化之后的值
 */
export function toMinNum(value) {
  return (parseFloat(value) / 100).toFixed(1)
}
/**
 * 百分比*100
 * @params {value} number | string - 公里
 * @returns {number} 格式化之后的值
 */
export function toMaxNum(value) {
  return parseFloat(value) * 100
}
/**
 * 秒转分
 * @params {value} number | string -
 * @returns {string} 格式化之后的值
 */
export function toMinue(value) {
  return parseFloat(value) / 60
}
/**
 * 分转秒
 * @params {value} number | string
 * @returns {number} 格式化之后的值
 */
export function toSecond(value) {
  return parseFloat(value) * 60
}
/**
 * 秒转时
 * @params {value} number | string -
 * @returns {string} 格式化之后的值
 */
export function toHour(value) {
  return parseFloat(value) / 3600
}
/**
 * 时转秒
 * @params {value} number | string
 * @returns {number} 格式化之后的值
 */
export function toHourSecond(value) {
  return parseFloat(value) * 3600
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function deepClone(obj) {
  //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
  let objClone = Array.isArray(obj) ? [] : {}
  //进行深拷贝的不能为空，并且是对象或者是
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach((v) => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

// 复制方法
export function copy(data) {
  const clipboardData = window.clipboardData || navigator.clipboard
  clipboardData.writeText(data)
  Message({
    message: '复制成功',
    type: 'success',
  })
}

export function downloadFile(result) {
  let name = result.headers['content-disposition'].split('filename=')[1]
  let file = window.URL.createObjectURL(new Blob([result.data]))
  let docUrl = document.createElement('a')

  docUrl.href = file
  docUrl.setAttribute('download', name)
  document.body.appendChild(docUrl)
  docUrl.click()
  window.URL.revokeObjectURL(docUrl.href)
}

// 通过城市id查找省id
export function find(list, value) {
  if (!list || !value) {
    return ''
  }
  let arr = []
  let findParent = (data, nodeId, parentId) => {
    for (var i = 0, length = data.length; i < length; i++) {
      let node = data[i]
      if (node.value == nodeId) {
        arr.unshift(data[i])
        if (nodeId == list[0].value) {
          break
        }
        findParent(list, parentId)
        break
      } else {
        if (node.children) {
          findParent(node.children, nodeId, node.value)
        }
        continue
      }
    }
    return arr
  }
  return findParent(list, value)
}

// 删除url上的参数
export function getnewurl(name) {
  var loca = window.location
  var baseUrl = loca.origin + loca.pathname + '?'
  var query = loca.search.substr(1)
  if (query.indexOf(name) > -1) {
    var obj = {}
    var arr = query.split('&')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    delete obj[name]
    var url =
      baseUrl +
      JSON.stringify(obj)
        .replace(/[\"\{\}]/g, '')
        .replace(/\:/g, '=')
        .replace(/\,/g, '&')
    return url
  }
}
