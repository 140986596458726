<template>
  <el-container class="layout-wrap">
    <el-header>
      <layout-header></layout-header>
    </el-header>
    <el-container class="bottom-wrap">
      <el-aside width="260px">
        <layout-aside></layout-aside>
      </el-aside>
      <el-main class="display-content">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import LayoutHeader from '@/views/main/layout/components/LayoutHeader'
import LayoutAside from '@/views/main/layout/components/LayoutAside'
export default {
  name: 'MainLayout',
  components: {
    'layout-header': LayoutHeader,
    'layout-aside': LayoutAside,
  },
  mounted() {
    this.$store.dispatch('main/getMenuTree')
    this.$store.dispatch('main/getUserInfo')
  },
}
</script>

<style scoped>
.el-header {
  background-color: #1f2d3d;
  border-bottom: 1px solid #000;
}

.el-aside {
  background-color: #2c3e50;
}

.el-main {
  padding: 0;
  background-color: #f2f5f7;
}

.layout-wrap {
  height: 100%;
}
.bottom-wrap {
  /* height: calc(100% - 60px); */
}

.display-content {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
</style>
