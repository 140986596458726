export default [
  // 协议管理
  {
    path: '/config/agreement-admin/list',
    name: 'AgreementAdmin',
    component: () => import('@/views/config/agreementAdmin/list/index.vue'),
    meta: { title: '协议管理', keepAlive: true },
  },
  {
    path: '/config/agreement-admin/list/newAgreement',
    name: 'NewAgreement',
    component: () =>
      import('@/views/config/agreementAdmin/commom/newAgreement'),
    meta: { title: '新建协议' },
  },
  {
    path: '/config/agreement-admin/list/details',
    name: 'AgreementDetails',
    component: () =>
      import('@/views/config/agreementAdmin/commom/agreementDetails.vue'),
    meta: { title: '协议详情' },
  },
]
