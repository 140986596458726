export default [
  // 用户配置CMS
  {
    path: '/config/configure-cms/user-cms',
    name: 'UserCms',
    component: () =>
      import('@/views/config/configureCms/configUserCms/list/index.vue'),
    meta: { title: '用户配置CMS', keepAlive: true },
  },
  {
    path: '/config/configure-cms/user-cms/newText',
    name: 'UserNewText',
    component: () => import('@/views/config/configureCms/commom/newText.vue'),
    meta: { title: '新建文本' },
  },
  {
    path: '/config/configure-cms/user-cms/textDetails',
    name: 'UserTextDetails',
    component: () =>
      import('@/views/config/configureCms/commom/textDetails.vue'),
    meta: { title: '文本详情' },
  },
  {
    path: '/config/configure-cms/user-cms/operationDetails',
    name: 'UserOperationDetails',
    component: () =>
      import('@/views/config/configureCms/commom/tab/operationDetails.vue'),
    meta: { title: '操作详情' },
  },

  // 司机配置CMS
  {
    path: '/config/configure-cms/driver-cms',
    name: 'DriverCms',
    component: () =>
      import('@/views/config/configureCms/configDriverCms/list/index.vue'),
    meta: { title: '司机配置CMS', keepAlive: true },
  },
  {
    path: '/config/configure-cms/driver-cms/newText',
    name: 'DriverNewText',
    component: () => import('@/views/config/configureCms/commom/newText.vue'),
    meta: { title: '新建文本' },
  },
  {
    path: '/config/configure-cms/driver-cms/textDetails',
    name: 'DriverTextDetails',
    component: () =>
      import('@/views/config/configureCms/commom/textDetails.vue'),
    meta: { title: '文本详情' },
  },
  {
    path: '/config/configure-cms/driver-cms/operationDetails',
    name: 'DriverOperationDetails',
    component: () =>
      import('@/views/config/configureCms/commom/tab/operationDetails.vue'),
    meta: { title: '操作详情' },
  },
]
