export default [
  // 员工
  {
    path: '/staff-center/organization/staff',
    component: () => import('@/views/staffCenter/organization/staff/index'),
    meta: { title: '员工', keepAlive: true },
  },
  {
    path: '/staff-center/organization/staff/detail',
    name: 'Staff_detail',
    component: () =>
      import('@/views/staffCenter/organization/staff/detail/detailedList.vue'),
  },
  {
    path: '/staff-center/organization/staff/create',
    name: 'Staff_create',
    component: () =>
      import('@/views/staffCenter/organization/staff/detail/createdList.vue'),
  },
  {
    path: '/staff-center/organization/staff/add_role',
    name: 'Add_role',
    component: () =>
      import('@/views/staffCenter/organization/staff/detail/batchAddRoles.vue'),
  },
  {
    path: '/staff-center/organization/staff/add_department',
    name: 'Add_department',
    component: () =>
      import(
        '@/views/staffCenter/organization/staff/detail/batchAddDepartment.vue'
      ),
  },

  // 部门
  {
    path: '/staff-center/organization/department',
    component: () =>
      import('@/views/staffCenter/organization/department/index'),
    meta: { title: '部门', keepAlive: true },
  },
  {
    path: '/staff-center/organization/department/detail',
    name: 'Department_detail',
    component: () =>
      import(
        '@/views/staffCenter/organization/department/detail/detailedList.vue'
      ),
  },

  // 角色
  {
    path: '/staff-center/organization/role',
    component: () => import('@/views/staffCenter/organization/role/index'),
    meta: { title: '角色', keepAlive: true },
  },
  {
    path: '/staff-center/organization/role/detail',
    name: 'Role_detail',
    component: () =>
      import('@/views/staffCenter/organization/role/tab/detailedList.vue'),
  },
  {
    path: '/staff-center/organization/edit_permissions',
    name: 'Edit_permissions',
    component: () =>
      import('@/views/staffCenter/organization/role/tab/rolePermissions.vue'),
  },
]
