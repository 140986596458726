export default [
  // 用户资料审核
  {
    path: '/user-center/user-audit/list',
    name: 'userAuditList',
    component: () => import('@/views/userCenter/userAudit/list/index'),
  },
  // 用户资料详情
  {
    path: '/user-center/user-audit/detail',
    name: 'userAuditDetail',
    component: () => import('@/views/userCenter/userAudit/detail/index'),
  },
]
