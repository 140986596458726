const jurisdiction = {
  namespaced: true,

  state: {
    staff_code_list: sessionStorage.getItem('get_staff_code_list') || null
  },

  mutations: {
    get_staff_code_list (state, id) {
      state.staff_code_list = id
    },
  },

  actions: {
    setStaffCodeList ({ commit }, id) {
      commit('get_staff_code_list', id)
      sessionStorage.setItem('get_staff_code_list', id)
    },
  },
}

export default jurisdiction
