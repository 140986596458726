// // 异步加载高德地图API
// export function loadMP() {
//   const mp = new Promise(function(resolve, reject) {
//     let hasLoaded1 = document.getElementById('amap')
//     if (hasLoaded1) {
//       // 只加载一次
//       return false
//     }
//     window.init = function() {
//       resolve(AMap)
//     }
//     let script = document.createElement('script')
//     script.type = 'text/javascript'
//     script.async = true
//     script.src =
//       '//webapi.amap.com/maps?v=2.0&key=f4dc089576c0de038bbcc73fe293a2c1&plugin=AMap.PolygonEditor,AMap.RectangleEditor,AMap.MouseTool&callback=init'
//     script.id = 'amap'
//     script.onerror = reject
//     document.head.appendChild(script)
//   })
//   const mpUI = new Promise(function(resolve, reject) {
//     let hasLoaded2 = document.getElementById('amapUI')
//     if (hasLoaded2) {
//       // 只加载一次
//       return false
//     }
//     let script2 = document.createElement('script')
//     script2.type = 'text/javascript'
//     script2.src = '//cache.amap.com/lbs/static/addToolbar.js'
//     script2.id = 'amapUI'
//     script2.onerror = reject
//     script2.onload = function(su) {
//       resolve()
//     }
//     document.head.appendChild(script2)
//   })
//   return Promise.all([mp, mpUI])
//     .then(function(result) {
//       return result
//     })
//     .catch(e => {
//       console.log(e)
//     })
// }

export function loadMP() {
  // <-- 原作者这里使用的是module.exports
  return new Promise((resolve, reject) => {
    if (window.AMap) {
      resolve(window.AMap)
    } else {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src =
        '//webapi.amap.com/maps?v=2.0&key=f4dc089576c0de038bbcc73fe293a2c1&plugin=AMap.PolygonEditor,AMap.RectangleEditor,AMap.MouseTool'
      script.onerror = reject
      document.head.appendChild(script)
    }
    window.initAMap = () => {
      resolve(window.AMap)
    }
  })
}
