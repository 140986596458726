<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style>
#app {
  height: 100%;
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
.el-table .el-table__cell {
  padding: 6px 0 !important;
}
</style>
