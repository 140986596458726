<template>
  <div>
    <h2>拉蜂OA系统</h2>
    <div>{{ user.fullname }},欢迎您！</div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  computed: {
    user() {
      return this.$store.state.main.userInfo
    },
  },
}
</script>

<style scoped>
h2 {
  margin-bottom: 10px;
}
</style>
