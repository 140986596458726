/**
 * 引导区地图
 */
import guideAreaApi from '@/api/driverOperation/guideArea'
import AMapPlugin from '@/utils/amap_plugin'

let timeRangeStart = new Date().setHours(0, 0, 0, 0)
let timeRangeEnd = new Date().setHours(23, 0, 0, 0)
let today = new Date().setHours(0, 0, 0, 0)
let nextDay = today + 24 * 3600 * 1000

const state = {
  mapStatus: '', // 地图页面状态 空=全屏，preview=预览，create=新建，edit=编辑
  map: null, // map插件
  guideArea: {}, // 引导区 {引导区code:引导区对象}
  polygonObj: null, // 新建引导区的对象
  weight: 2, // 权重
  dateRange: [new Date(today), new Date(nextDay)], // 日期范围
  timeRange: [[new Date(timeRangeStart), new Date(timeRangeEnd)]], // 时间段
  areaName: '', // 引导区名称
  areaTitle: '', // 引导区标题
  selectedGuideAreaCode: '', // 当前选中的引导区code
  pois: [], // 搜索poi结果数组
  searchTimeRange: null,
}

const mutations = {
  setMapStatus(state, mapStatus) {
    state.mapStatus = mapStatus
    if (mapStatus === 'edit') {
      state.map.editPolygon(state.selectedGuideAreaCode)
    }
  },
  setGuideAreaMap(state, areaList) {
    let map = {}
    for (let area of areaList) {
      map[area.guide_area_code] = area
    }
    state.guideArea = map
  },
  setMapInstance(state, map) {
    state.map = map
  },
  setPolygonObj(state, polygonObj) {
    state.polygonObj = polygonObj
  },
  setDateRange(state, dateRange) {
    state.dateRange = dateRange
  },
  setTimeRange(state, timeRange) {
    state.timeRange = timeRange
  },
  addTimeRange(state) {
    state.timeRange.push([new Date(timeRangeStart), new Date(timeRangeEnd)])
  },
  removeTimeRange(state, index) {
    state.timeRange.splice(index, 1)
  },
  setWeight(state, weight) {
    state.weight = weight
  },
  setAreaName(state, areaName) {
    state.areaName = areaName
  },
  setAreaTitle(state, areaTitle) {
    state.areaTitle = areaTitle
  },
  clearDate(state) {
    state.weight = 2
    state.dateRange = [new Date(today), new Date(nextDay)]
    state.timeRange = [[new Date(timeRangeStart), new Date(timeRangeEnd)]]
    state.areaName = ''
    state.areaTitle = ''
    if (state.polygonObj) {
      // 清除新建的多边形
      state.map.remove(state.polygonObj)
      state.polygonObj = null
    }
    state.map.stopEditPolygon() // 停止编辑多边形
    state.map.stopDrawPolygon() // 停止新建多边形
    state.selectedGuideAreaCode = ''
  },
  selectGuideArea(state, guideAreaCode) {
    state.selectedGuideAreaCode = guideAreaCode

    let area = state.guideArea[guideAreaCode]
    let valid_dates = area.valid_dates.map((item) => parseDate(item))
    let valid_times = area.valid_times.map((range) =>
      range.map((item) => parseTime(item))
    )
    state.areaTitle = area.title
    state.areaName = area.name
    state.weight = area.weight
    state.dateRange = valid_dates
    state.timeRange = valid_times
  },
  setPois(state, pois) {
    state.pois = pois
  },
  clearPois(state) {
    state.pois = []
  },
  setSearchTimeRange(state, timeRange) {
    state.searchTimeRange = timeRange
  },
}

const actions = {
  /**
   * 搜索引导区
   * @param cityId String 城市id
   * @param validTime [Date,Date] 有效时间
   */
  async searchGuideArea({ state, commit, dispatch }, { cityId, validTime }) {
    // 多个事件同时触发请求时去重
    if (this.querying) {
      return
    }
    this.querying = true
    setTimeout(() => {
      this.querying = false
    }, 100)
    // 缓存cityId、validTime
    if (cityId) {
      this.cityId = cityId
    } else {
      cityId = this.cityId
    }
    if (validTime !== undefined) {
      this.validTime = validTime
    } else {
      validTime = this.validTime
    }
    // Date转String
    let validTimeStr
    if (validTime) {
      validTimeStr = validTime.map(formatDateTime)
    }
    let ret = await guideAreaApi.searchGuideArea({
      cityId: cityId,
      validTime: validTimeStr,
      areaStatus: 1,
    })
    // 设置state
    commit('setGuideAreaMap', ret.list)
    // 画多边形
    dispatch('showPolygon', ret.list)
  },
  /**
   * 显示引导区
   * @param list 引导区列表
   */
  showPolygon({ state, commit, dispatch }, list) {
    if (!state.map) {
      setTimeout(() => {
        dispatch('showPolygon', list)
      }, 1000)
      return
    }
    // 删除已经存在的多边形
    state.map.removeAll()
    for (let area of list) {
      let { name, guide_area_code, gis, weight } = area
      let fillOpacity = weight === 1 ? 0.6 : weight === 2 ? 0.3 : 0.1
      state.map.showPolygon(
        guide_area_code,
        gis,
        '#FF8D1A',
        fillOpacity,
        { name, code: guide_area_code },
        (polygonId) => {
          // 新建时，不能选择其他区域
          if (state.mapStatus === '' || state.mapStatus === 'preview') {
            commit('selectGuideArea', polygonId)
            commit('setMapStatus', 'preview')
          }
        }
      )
    }
  },
  /**
   * 初始化地图
   * @param cityName 当前城市名称
   * @param containerId 地图容器id
   */
  async initMap({ state, commit }, { cityName, containerId }) {
    let map = new AMapPlugin()
    await map.init(containerId)
    commit('setMapInstance', map)
    if (cityName) {
      let geocodes = await map.getLocation(cityName)
      let { lng, lat } = geocodes[0].location
      map.setMapCenter({ lng, lat })
    }
  },
  /**
   * 画多边形
   */
  async drawPolygon({ state, commit, dispatch }) {
    if (!state.map) {
      return
    }
    let polygon = await state.map.drawPolygon()
    if (polygon.getPath().length < 3) {
      state.map.stopDrawPolygon()
      state.map.remove(polygon)
      return Promise.reject(new Error('新建引导区至少需要3个点，请重试'))
    }
    for (let p of polygon.getPath()) {
      let addr = await state.map.getAddress(p.lng, p.lat)
      let { adcode } = addr.addressComponent
      if (
        Math.floor(this.cityId / 100) !== Math.floor(parseInt(adcode) / 100)
      ) {
        state.map.stopDrawPolygon()
        state.map.remove(polygon)
        return Promise.reject(new Error('引导区超出当前城市范围'))
      }
    }
    commit('setPolygonObj', polygon)
    return true
  },
  /**
   * 清除多边形
   */
  stopDrawPolygon({ state, commit }) {
    console.log('stopDrawPolygon')
    if (state.polygonObj) {
      state.map.stopDrawPolygon()
      state.map.remove(state.polygonObj)
      commit('setPolygonObj', null)
    }
  },
  /**
   * 设置地图显示的城市
   */
  async setMapCity({ state }, cityName) {
    let geocodes = await state.map.getLocation(cityName)
    let { lng, lat } = geocodes[0].location
    // state.map.setMapCenter({ lng, lat, immediately: false, duration: 300 })
    state.map.setMapZoomAndCenter({
      zoom: 12,
      lng,
      lat,
      immediately: false,
      duration: 300,
    })
  },
  /**
   * 设置地图中心
   */
  async setMapCenter({ state }, { zoom, lng, lat }) {
    state.map.setMapZoomAndCenter({
      zoom,
      lng,
      lat,
      immediately: false,
      duration: 300,
    })
  },
  /**
   * 搜索poi
   * @param address 地址
   * @param cityName 城市
   */
  async searchPOI({ commit }, { address, cityName }) {
    let pois = await state.map.searchPOI(address, cityName)
    commit('setPois', pois)
  },
  /**
   * 新建引导区
   * @param cityId
   * @returns {Promise<void>}
   */
  async createGuideArea({ state, dispatch }) {
    if (state.areaName === '') {
      throw new Error('请填写引导区名称')
    }
    if (state.areaTitle === '') {
      throw new Error('请填写引导区标题')
    }
    if (state.timeRange.length === 0) {
      throw new Error('请添加时间段')
    }
    if (!state.polygonObj) {
      throw new Error('请画出引导区')
    }
    if (state.dateRange[1].getTime() < today) {
      throw new Error('结束日期必须大于当前时间')
    }
    // 引导区位置校验
    for (let p of state.polygonObj.getPath()) {
      let addr = await state.map.getAddress(p.lng, p.lat)
      let { adcode } = addr.addressComponent
      if (
        Math.floor(this.cityId / 100) !== Math.floor(parseInt(adcode) / 100)
      ) {
        return Promise.reject(new Error('引导区超出当前城市范围'))
      }
    }
    let validDates = state.dateRange.map(formatDate)
    let validTimes = state.timeRange.map((range) => range.map(formatTime))
    checkTimeRange(validTimes)
    let gis = state.polygonObj.getPath().map((p) => [p.lng, p.lat])
    let result = await guideAreaApi.addGuideArea(
      state.areaName,
      state.areaTitle,
      this.cityId,
      validDates,
      validTimes,
      state.weight,
      gis
    )
    dispatch('stopDrawPolygon')
    dispatch('cancelEditor')
    return result
  },

  /**
   * 编辑引导区
   * @param cityId
   * @returns {Promise<void>}
   */
  async editGuideArea({ state, dispatch }) {
    if (state.areaName === '') {
      throw new Error('请填写引导区名称')
    }
    if (state.areaTitle === '') {
      throw new Error('请填写引导区标题')
    }
    if (state.timeRange.length === 0) {
      throw new Error('请添加时间段')
    }
    let areaCode = state.selectedGuideAreaCode
    // 引导区位置校验
    for (let p of state.map.getPolygonById(areaCode).getPath()) {
      let addr = await state.map.getAddress(p.lng, p.lat)
      let { adcode } = addr.addressComponent
      if (
        Math.floor(this.cityId / 100) !== Math.floor(parseInt(adcode) / 100)
      ) {
        return Promise.reject(new Error('引导区超出当前城市范围'))
      }
    }
    let validDates = state.dateRange.map(formatDate)
    let validTimes = state.timeRange.map((range) => range.map(formatTime))
    checkTimeRange(validTimes)
    let gis = state.map
      .getPolygonById(areaCode)
      .getPath()
      .map((p) => [p.lng, p.lat])
    let result = await guideAreaApi.editGuideArea(areaCode, {
      name: state.areaName,
      title: state.areaTitle,
      valid_dates: validDates,
      valid_times: validTimes,
      weight: state.weight,
      gis,
    })
    dispatch('cancelEditor')
    return result
  },

  /**
   * 取消编辑
   */
  cancelEditor({ commit, dispatch }) {
    commit('clearDate')
    dispatch('searchGuideArea', {})
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

/**
 * 格式化日期时间
 * @param date date对象
 * @returns {string} 格式化的字符串
 */
function formatDateTime(date) {
  let y = fill(date.getFullYear(), 4)
  let m = fill(date.getMonth() + 1, 2)
  let d = fill(date.getDate(), 2)
  let h = fill(date.getHours(), 2)
  let min = fill(date.getMinutes(), 2)
  let s = fill(date.getSeconds(), 2)
  return `${y}-${m}-${d} ${h}:${min}:${s}`
}

/**
 * 格式化时间
 * @param date date对象
 * @returns {string} 格式化的字符串
 */
function formatTime(date) {
  let h = fill(date.getHours(), 2)
  let min = fill(date.getMinutes(), 2)
  return `${h}:${min}`
}

/**
 * 格式化日期
 * @param date date对象
 * @returns {string} 格式化的字符串
 */
function formatDate(date) {
  let y = fill(date.getFullYear(), 4)
  let m = fill(date.getMonth() + 1, 2)
  let d = fill(date.getDate(), 2)
  return `${y}-${m}-${d}`
}

/**
 * 解析日期
 * @param date 日期字符串
 */
function parseDate(date) {
  let arr = date.split('-')
  arr = arr.map((item) => parseInt(item))
  return new Date(arr[0], arr[1] - 1, arr[2])
}

/**
 * 解析时间
 * @param time
 */
function parseTime(time) {
  let arr = time.split(':')
  arr = arr.map((item) => parseInt(item))
  let ts = new Date().setHours(arr[0], arr[1], 0, 0)
  return new Date(ts)
}

/**
 * 补0，并转为字符串
 * @param number 数字
 * @param length 长度
 * @returns {string} 补0后的字符串
 */
function fill(number, length) {
  return number.toString().padStart(length, '0')
}

/**
 * 校验时间段参数
 * @param timeRange
 */
function checkTimeRange(timeRange) {
  for (let item of timeRange) {
    if (item[0] === item[1]) {
      throw new Error('有效时段的开始时间不能等于结束时间')
    }
  }
  for (let n = 0; n < timeRange.length - 1; n++) {
    for (let m = n + 1; m < timeRange.length; m++) {
      let [t1, t2] = timeRange[n]
      let [t3, t4] = timeRange[m]
      if ((t1 >= t3 && t1 < t4) || (t2 > t3 && t2 <= t4)) {
        throw new Error('有效时段不能重叠')
      }
    }
  }
}
