// 邮寄发票
export default [
  {
    path: '/finance/receipt/mailInvoice',
    name: 'mailInvoice',
    component: () =>
      import('@/views/finance/receipt/mailInvoice/list/index.vue'),
    meta: { title: '邮寄发票列表', keepAlive: true },
  },
  {
    path: '/finance/receipt/mailInvoice/dataImport',
    name: 'mailInvoice',
    component: () =>
      import('@/views/finance/receipt/electronicInvoice/detail/dataImport.vue'),
    meta: { title: '数据导入' },
  },
]
