export default [
  // 司机招募列表
  {
    path: '/user-center/driver-recruit/list',
    name: 'track',
    component: () => import('@/views/userCenter/driverRecruit/list/index'),
    meta: { keepAlive: true },
  },
  // 司机招募
  {
    path: '/user-center/driver-recruit/list/audit',
    name: 'Audit',
    component: () =>
      import('@/views/userCenter/driverRecruit/details/auditInfo'),
  },

  // 司机招募详情
  {
    path: '/user-center/driver-recruit/list/audit-detail',
    name: 'AuditDetail',
    component: () =>
      import('@/views/userCenter/driverRecruit/details/auditDetail'),
  },
  {
    path: '/user-center/driver-recruit/list/add',
    name: 'applyAdd',
    hidden: true,
    component: () => import('@/views/userCenter/driverRecruit/add/index'),
  },
  {
    path: '/user-center/check/list',
    name: 'check',
    component: () => import('@/views/userCenter/driverRecruit/check/index'),
  },
  {
    path: '/user-center/track/list',
    name: 'track',
    component: () => import('@/views/userCenter/driverRecruit/track/index'),
  },
  {
    path: '/user-center/apply/list',
    name: 'apply',
    component: () =>
      import('@/views/userCenter/driverRecruit/apply/list/index'),
  },

  {
    path: '/user-center/apply/detail/:id',
    name: 'applyDetail',
    hidden: true,
    component: () =>
      import('@/views/userCenter/driverRecruit/apply/detail/index'),
  },

  /**
   * 企业
   */

  // 企业司机招募
  {
    path: '/user-center/driver-recruit/list/enterpriseDriverAudit',
    name: 'enterpriseDriverAudit',
    component: () =>
      import('@/views/userCenter/driverRecruit/details/enterpriseDriverAudit'),
  },

  // 企业司机招募详情
  {
    path: '/user-center/driver-recruit/list/enterpriseAuditDetail',
    name: 'enterpriseAuditDetail',
    component: () =>
      import(
        '@/views/userCenter/driverRecruit/details/enterpriseAuditDetail.vue'
      ),
  },
]
