import commonApi from '@/api/driverOperation/common'

const state = {
  openCityList: [], // [{city_id:440100,city_name:'广州市'},{city_id:440300,city_name:'深圳市'}...]
  openCityIdToName: {}, // { 440100:'广州市', 440300:'深圳市' ...}
  currentCityId: parseInt(
    window.localStorage.getItem('current_city_id') ?? '440100'
  ), // 当前城市id
  currentCityName: window.localStorage.getItem('current_city_name') ?? '广州市', // 当前城市名称
}

const mutations = {
  /**
   * 设置开放城市列表
   * @param cityList 城市列表
   */
  setOpenCityList(state, cityList) {
    let map = {}
    for (let item of cityList) {
      map[item.city_id] = item.city_name
    }
    state.openCityList = cityList
    state.openCityIdToName = map
  },
  /**
   * 设置当前选中的城市
   * @param cityId 城市id
   */
  setCurrentCityId(state, cityId) {
    state.currentCityId = cityId
    window.localStorage.setItem('current_city_id', cityId)
    state.currentCityName = state.openCityIdToName[cityId]
    window.localStorage.setItem('current_city_name', state.currentCityName)
  },
}

const actions = {
  /**
   * 获取开放城市列表
   */
  async fetchOpenCityList({ commit }) {
    let rsp = await commonApi.queryOpenCityList()
    commit('setOpenCityList', rsp.data.open_city_list)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
