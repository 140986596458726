import orderConfigure from './orderConfigure'
import config from './config'
import configureCms from './configureCms'
import agreementAdmin from './agreementAdmin'
import insureConfigure from './insureConfigure'
import collection from './collection'
import dispatch from './dispatch'

export default [
  ...orderConfigure,
  ...config,
  ...configureCms,
  ...agreementAdmin,
  ...insureConfigure,
  ...collection,
  ...dispatch,
]
