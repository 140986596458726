export default [
  // 司机收件箱消息计划
  {
    path: '/message-management/driver-message/inbox-message',
    name: 'InboxMessage',
    component: () =>
      import(
        '@/views/messageManagement/driverMessage/inboxMessage/list/index.vue'
      ),
    meta: { title: '司机收件箱消息计划', keepAlive: true },
  },
  {
    path: '/message-management/driver-message/inbox-message/newInboxMessage',
    name: 'NewInboxMessage',
    component: () =>
      import('@/views/messageManagement/components/newInboxMessage.vue'),
    meta: { title: '新建司机收件箱计划' },
  },
  {
    path: '/message-management/driver-message/inbox-message/inboxDetails',
    name: 'InboxDetails',
    component: () =>
      import('@/views/messageManagement/components/inboxDetails.vue'),
    meta: { title: '司机收件箱计划详情' },
  },

  // 用户收件箱消息计划
  {
    path: '/message-management/user-message/inbox-message',
    name: 'InboxMessage',
    component: () =>
      import(
        '@/views/messageManagement/userMessage/inboxMessage/list/index.vue'
      ),
    meta: { title: '用户收件箱消息计划', keepAlive: true },
  },
  {
    path: '/message-management/user-message/inbox-message/newInboxMessage',
    name: 'NewInboxMessage',
    component: () =>
      import('@/views/messageManagement/components/newInboxMessage.vue'),
    meta: { title: '新建用户收件箱计划' },
  },
  {
    path: '/message-management/user-message/inbox-message/inboxDetails',
    name: 'InboxDetails',
    component: () =>
      import('@/views/messageManagement/components/inboxDetails.vue'),
    meta: { title: '司机收件箱计划详情' },
  },
]
