const carGroup = {
  namespaced: true,

  state: {
    tableData: {},
  },

  mutations: {
    SET_CITY_ORDER_VEHICLE_ID: (state, val) => {
      state.tableData = val
    },
  },

  actions: {
    setCityOrderVehicleId({ commit }, val) {
      commit('SET_CITY_ORDER_VEHICLE_ID', val)
    },
  },
}

export default carGroup
