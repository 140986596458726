export default [
  // 保险订单
  {
    path: '/user-center/order-admin/insure-order',
    name: 'insureOrder',
    component: () => import('@/views/userCenter/orderAdmin/insureOrder/list'),
    meta: { title: '保险订单', keepAlive: true },
  },
  {
    path: '/user-center/order-admin/insure-order/detail',
    name: 'orderDetails',
    component: () =>
      import('@/views/userCenter/orderAdmin/insureOrder/details'),
    meta: { title: '保险订单详情' },
  },

  // 投保记录
  {
    path: '/user-center/order-admin/insure-record',
    name: 'insureRecord',
    component: () => import('@/views/userCenter/orderAdmin/insureRecord/list'),
    meta: { keepAlive: true },
  },
  // 议价列表
  {
    path: '/user-center/order-admin/dispatch-order',
    name: 'dispatchOrder',
    component: () => import('@/views/userCenter/orderAdmin/dispatchOrder/list'),
    meta: { keepAlive: true },
  },
  {
    path: '/user-center/order-admin/dispatch-order/detail',
    name: 'dispatchOrderDetail',
    component: () =>
      import('@/views/userCenter/orderAdmin/dispatchOrder/detail'),
    meta: { title: '议价详情' },
  },
]
