export default [
  // 用户CMS
  {
    path: '/content-management/user-cms',
    name: 'UserCms',
    component: () => import('@/views/contentManagement/userCms/list/index.vue'),
    meta: { title: '用户CMS', keepAlive: true },
  },
  {
    path: '/content-management/user-cms/newText',
    name: 'UserNewText',
    component: () => import('@/views/contentManagement/commom/newText.vue'),
    meta: { title: '新建文本' },
  },
  {
    path: '/content-management/user-cms/textDetails',
    name: 'UserTextDetails',
    component: () => import('@/views/contentManagement/commom/textDetails.vue'),
    meta: { title: '文本详情' },
  },
  {
    path: '/content-management/user-cms/operationDetails',
    name: 'UserOperationDetails',
    component: () =>
      import('@/views/contentManagement/commom/tab/operationDetails.vue'),
    meta: { title: '操作详情' },
  },

  // 司机CMS
  {
    path: '/content-management/driver-cms',
    name: 'DriverCms',
    component: () =>
      import('@/views/contentManagement/driverCms/list/index.vue'),
    meta: { title: '司机CMS', keepAlive: true },
  },
  {
    path: '/content-management/driver-cms/newText',
    name: 'DriverNewText',
    component: () => import('@/views/contentManagement/commom/newText.vue'),
    meta: { title: '新建文本' },
  },
  {
    path: '/content-management/driver-cms/textDetails',
    name: 'DriverTextDetails',
    component: () => import('@/views/contentManagement/commom/textDetails.vue'),
    meta: { title: '文本详情' },
  },
  {
    path: '/content-management/driver-cms/operationDetails',
    name: 'DriverOperationDetails',
    component: () =>
      import('@/views/contentManagement/commom/tab/operationDetails.vue'),
    meta: { title: '操作详情' },
  },
]
