<template>
  <div class="wrap flex flex-justify-center flex-align-center">
    <div
      class="box flex flex-v flex-align-start"
      v-if="google_secret_expired == 'true' && verification_state == false"
    >
      <div class="title">设置动态验证码的身份认证器</div>
      <div class="tips">
        ！请务必保管好密钥，如因更换或丢失手机号导致无法登录，请联系管理员重置谷歌验证器
      </div>
      <div>
        <div class="step">
          步骤一：在您的设备中下载
          <el-popover placement="right" width="350" trigger="hover">
            <div>请扫码下载应用 Google Authenticator</div>
            <div class="google_code_list">
              <div class="google_code">
                <div class="qr_code">
                  <vue-qr
                    text="https://lafeng-files.oss-cn-shenzhen.aliyuncs.com/static/app_package/%E8%BA%AB%E4%BB%BD%E9%AA%8C%E8%AF%81%E5%99%A8.apk"
                    :size="140"
                  ></vue-qr>
                </div>
                <div>Androld</div>
              </div>
              <div class="google_code">
                <div class="qr_code">
                  <vue-qr
                    text="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    :size="140"
                  ></vue-qr>
                </div>
                <div>IOS</div>
              </div>
            </div>
            <el-button slot="reference" type="text">
              Google Authenticator
            </el-button>
          </el-popover>
          应用。单击"立即添加">扫描下方二维码。
        </div>
        <div class="google_code_secret_key">
          <div class="google_code">
            <div class="qr_code">
              <vue-qr :text="qrcode" :size="140"></vue-qr>
            </div>
            <div class="secret_key">
              <div class="ti">手动输入密钥</div>
              {{ qrcodeInfo.google_secret }}
            </div>
          </div>
        </div>
        <div class="step">
          步骤二：请输入您在
          <span>Google Authenticator</span>
          应用获取的验证码
        </div>
        <div>
          <el-input
            v-model="google_code"
            placeholder="请输入动态验证码"
            maxlength="8"
            style="margin: 15px 0"
          ></el-input>
        </div>
        <el-button style="width: 100%" type="primary" @click="verification">
          绑 定
        </el-button>
      </div>
    </div>
    <div
      class="box flex flex-v flex-align-start"
      v-if="password_expired == 'true' && verification_state"
    >
      <div class="title">请设置登录密码</div>
      <el-tag class="tag" type="info">
        6~20位，大小写字母、数字、符号2种以上组合
      </el-tag>
      <el-form :model="formData" :rules="rules" ref="form">
        <el-form-item prop="password">
          <el-input
            v-model="formData.password"
            placeholder="请输入密码"
            show-password
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <el-input
            v-model="formData.password2"
            placeholder="请再次输入密码"
            show-password
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-btn" type="primary" @click="submit">
            确认
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import mainApi from '@/api/main/mainApi'
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr,
  },
  name: 'ChangePsw',
  data() {
    return {
      api_token: window.localStorage.getItem('api_token'),
      password_expired: this.$route.query.password_expired,
      google_secret_expired: this.$route.query.google_secret_expired,
      imageUrl: '',
      qrcode: '',
      verification_state: false,
      qrcodeInfo: '',
      google_code: '',
      formData: {
        password: '',
        password2: '',
      },
      rules: {
        password2: [{ validator: this.password2Validator, trigger: 'blur' }],
        password: [{ validator: this.passwordValidator, trigger: 'blur' }],
      },
      status: false,
    }
  },
  created() {
    this.getGoogleQrcodeUrl()
  },
  mounted() {
    if (this.google_secret_expired == 'false') {
      this.verification_state = true
    }
  },
  methods: {
    verification() {
      if (!this.google_code) return this.$message.error('请输入谷歌验证码')
      mainApi
        .verifyGoogleCode({
          code: this.google_code,
          google_secret: this.qrcodeInfo.google_secret,
        })
        .then(() => {
          this.verification_state = true
          this.$message({
            message: '绑定成功',
            type: 'success',
          })
          if (this.password_expired == 'false') {
            this.$router.replace('/login')
          }
        })
    },
    getGoogleQrcodeUrl() {
      mainApi.getGoogleQrcodeUrlApi().then((res) => {
        // console.log(res)
        this.qrcode = res.qrcode
        this.qrcodeInfo = res
      })
    },
    submit() {
      if (this.google_secret_expired == 'true') {
        if (!this.verification_state)
          return this.$message.error('请先验证谷歌验证码')
      }

      if (this.password_expired == 'false' && this.verification_state) {
        this.$router.replace('/login')
        return
      }

      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch('main/changePassword', this.formData.password)
            .then(() => {
              this.$message({
                message: '设置成功，请重新登录',
                type: 'success',
              })
              this.$router.replace('/login')
            })
            .catch((err) => {
              this.$message.error('修改密码失败:' + err.toString())
            })
        }
      })
    },
    /**
     * 密码参数校验
     */
    passwordValidator(rule, value, callback) {
      if (value.length < 6 || value.length > 20) {
        callback(new Error('请输入6-20位密码'))
      }
      let n = 0
      n += /[A-Z]/.test(value) ? 1 : 0
      n += /[a-z]/.test(value) ? 1 : 0
      n += /\d/.test(value) ? 1 : 0
      n += /[~!@#$%^&*()_+{}|:"<>?.\-=[\]\\;',./]/.test(value) ? 1 : 0
      if (n < 2) {
        callback(new Error('请输入大小写字母、数字、符号2种以上组合'))
      }
      callback()
    },
    password2Validator(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100%;
  background: #f8f8f8;
}
.title {
  font-size: 20px;
  margin-bottom: 20px;
  i {
    color: #67c23a;
  }
}
.tag {
  width: 100%;
  margin-bottom: 10px;
}
.box {
  width: 470px;
  border: 1px solid #ddd;
  box-shadow: 0 5px 10px #ddd;
  background: #fff;
  border-radius: 5px;
  padding: 40px 30px 20px;

  .tips {
    color: #e8b05d;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .step {
    color: #666;
    font-size: 14px;
    span {
      color: #479efd;
    }
  }

  .user_name {
    color: #666;
    font-size: 14px;
    margin: 10px 0;
  }

  .remind {
    color: #e5a343;
    font-size: 14px;
    margin-top: 6px;
  }

  .google_code_input {
    display: flex;
    // margin-bottom: 35px;
    .el-input {
      margin-right: 10px;
    }
  }

  .password_specification {
    color: #777;
    font-size: 14px;
    margin: 10px 0;
  }
}
.el-form {
  width: 100%;
}
.submit-btn {
  margin-top: 20px;
  width: 100%;
}

.google_code_list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .google_code {
    text-align: center;
    color: #666;

    .qr_code {
      width: 140px;
      height: 140px;
      // background-color: pink;

      a {
        font-size: 14px;
        line-height: 140px;
        color: #4785f2;
        text-decoration: underline;
      }
    }
  }
}

.google_code_secret_key {
  .google_code {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .secret_key {
    font-size: 16px;
    text-align: left;
    color: #444;
    font-weight: 500;
    text-align: center;
    .ti {
      font-size: 14px;
      color: #777;
      font-weight: 400;
    }
  }
}

.reset_password {
  margin-top: 30px;
  width: 100%;
}
</style>
