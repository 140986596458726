const state = {
  organization_uuid: ''
}

const mutations = {
  SET_UUID: (state, data) => {
    // eslint-disable-next-line no-prototype-builtins
    state.organization_uuid = data
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

