/**
 * 高德地图封装类
 */
import { loadMP } from '@/utils/amap.js'
class AMapPlugin {
  constructor() {
    this.polygonMap = {}
  }
  /**
   * 初始化地图插件
   * 需要在html中添加 <script src="https://webapi.amap.com/loader.js"></script>
   * @param containerId 地图容器标题的id
   * @returns {Promise<boolean>} 可以使用await调用本方法，成功后返回true
   */
  init(containerId) {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      loadMP().then((AMap) => {
        if (this.map == null) {
          AMap.plugin(
            [
              'AMap.MouseTool',
              'AMap.PlaceSearch',
              'AMap.Geocoder',
              'AMap.PolygonEditor',
            ],
            () => {
              this.map = new AMap.Map(containerId, {
                // center: [116.471354, 39.994257],
                center: [116.397428, 39.90923],
                zoom: 12,
                doubleClickZoom: false,
              })
              resolve(true)
            }
          )
          this.AMap = AMap
        }
      })
      // AMapLoader.load({
      //   key: 'f4dc089576c0de038bbcc73fe293a2c1',
      //   version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //   plugins: [
      //     'AMap.MouseTool',
      //     'AMap.PlaceSearch',
      //     'AMap.Geocoder',
      //     'AMap.PolygonEditor',
      //   ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      // }).then((AMap) => {
      //   this.AMap = AMap
      //   this.map = new AMap.Map(containerId, {
      //     center: [116.397428, 39.90923],
      //     zoom: 12,
      //     doubleClickZoom: false,
      //   })
      //   resolve(true)
      // })
    })
  }

  /**
   * 根据地址查poi信息
   * @param address 地址
   * @param city 城市名称，不填=全国
   * @returns {Promise<Array>} 成功后返回poi数组
   */
  getLocation(address, city) {
    return new Promise((resolve, reject) => {
      this.AMap.plugin('AMap.Geocoder', () => {
        let geocoder = new this.AMap.Geocoder({ city: city ? city : '全国' })
        geocoder.getLocation(address, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            resolve(result.geocodes)
          } else {
            reject(`定位失败, status:${status}, result:${result}`)
          }
        })
      })
    })
  }

  /**
   * 根据经纬度查地址信息
   * @param lng
   * @param lat
   * @returns {Promise<unknown>}
   */
  getAddress(lng, lat) {
    return new Promise((resolve, reject) => {
      console.log(2134)
      let geocoder = new this.AMap.Geocoder({ city: '全国' })
      console.log(213456)
      geocoder.getAddress([lng, lat], function (status, result) {
        console.log(2114, result)
        if (status === 'complete' && result.info === 'OK') {
          resolve(result.regeocode || result)
        } else {
          reject(`逆编码失败, status:${status}, result:${result}`)
        }
      })
    })
  }

  /**
   * 搜索兴趣点
   */
  searchPOI(address, city) {
    return new Promise((resolve, reject) => {
      let placeSearch = new this.AMap.PlaceSearch({
        pageSize: 5, // 单页显示结果条数
        pageIndex: 1, // 页码
        city, // 兴趣点城市
        citylimit: true, //是否强制限制在设置的城市内搜索
      })
      //关键字查询
      placeSearch.search(address, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          resolve(result.poiList.pois)
        } else {
          reject(`定位失败, status:${status}, result:${result}`)
        }
      })
    })
  }

  /**
   * 设置地图中心
   * @param lng 经度
   * @param lat 纬度
   */
  setMapCenter({ lng, lat, immediately, duration }) {
    this.map.setCenter([lng, lat], immediately, duration)
  }

  setMapZoomAndCenter({ zoom, lng, lat, immediately, duration }) {
    this.map.setZoomAndCenter(zoom, [lng, lat], immediately, duration)
  }

  /**
   * 画多边形
   * @returns {Promise} 成功后
   */
  drawPolygon() {
    return new Promise((resolve) => {
      this.mouseTool = new AMap.MouseTool(this.map)
      this.mouseTool.on('draw', (e) => {
        this.mouseTool.close(false)
        this.PolygonEditor = new this.AMap.PolygonEditor(this.map, e.obj)
        this.PolygonEditor.open()
        resolve(e.obj)
      })
      this.mouseTool.polygon({
        fillColor: '#ff0000',
        strokeColor: '#ff0000',
      })
    })
  }

  /**
   * 停止画多边形
   */
  stopDrawPolygon() {
    this.stopEditPolygon()
    if (this.mouseTool) {
      this.mouseTool.close(true)
    }
  }

  /**
   * 显示多边形
   * @param polygonId 多边形id,自定义的唯一标识
   * @param pointList 构成多边形的顶点数组
   * @param fillColor 填充颜色
   * @param fillOpacity 填充透明度
   * @param extData 多边形数据
   * @param onSelect 选择多边形的回调函数
   */
  showPolygon(polygonId, pointList, fillColor, fillOpacity, extData, onSelect) {
    if (this.polygonMap[polygonId]) {
      this.remove(this.polygonMap[polygonId])
    }
    let path = pointList.map((p) => new this.AMap.LngLat(p[0], p[1]))
    let polygon = new this.AMap.Polygon({
      path: path,
      fillColor: fillColor,
      fillOpacity: fillOpacity,
      strokeColor: fillColor,
      strokeOpacity: fillOpacity + 0.3,
      strokeStyle: 'dashed',
      extData,
    })
    polygon.on('mouseover', (e) => {
      let { name, code } = extData
      this.showText(`${name} ${code}`, e.lnglat.lng, e.lnglat.lat)
      polygon.setOptions({
        fillOpacity: fillOpacity + 0.2,
      })
    })
    polygon.on('mouseout', () => {
      this.removeText()
      polygon.setOptions({
        fillOpacity: fillOpacity,
      })
    })
    polygon.on('click', () => {
      if (onSelect && !this.PolygonEditor) {
        onSelect(polygonId)
      }
    })
    this.map.add(polygon)
    this.polygonMap[polygonId] = polygon
  }

  /**
   * 显示文本标签
   * @param content
   * @param lng
   * @param lat
   */
  showText(content, lng, lat) {
    clearInterval(this.removeTextTimer)
    if (this.text) {
      this.text.remove()
      this.text = null
    }
    this.text = new this.AMap.Text({
      text: content,
      anchor: 'bottom-left',
      clickable: false,
      style: {
        padding: '5px 10px',
        'border-radius': '4px',
        'background-color': '#ffffff',
        border: 'none',
        'text-align': 'center',
        'font-size': '12px',
        color: '#999',
        'pointer-events': 'none',
      },
      position: [lng, lat],
    })
    this.text.setMap(this.map)
  }

  /**
   * 删除文本标签
   */
  removeText() {
    clearInterval(this.removeTextTimer)
    this.removeTextTimer = setTimeout(() => {
      if (this.text) {
        this.text.remove()
        this.text = null
      }
    }, 500)
  }

  /**
   * 使用多边形id删除
   * @param polygonId 多边形id
   */
  removeByPolygonId(polygonId) {
    if (this.polygonMap[polygonId]) {
      this.remove(this.polygonMap[polygonId])
      delete this.polygonMap[polygonId]
    }
  }

  /**
   * 获取多边形对象
   * @param polygonId 多边形id
   * @returns Object 多边形对象
   */
  getPolygonById(polygonId) {
    return this.polygonMap[polygonId]
  }

  /**
   * 删除覆盖物
   * @param overlays 覆盖物对象数组
   */
  remove(overlays) {
    this.map.remove([overlays])
  }

  /**
   * 删除所有多边形
   */
  removeAll() {
    for (let code in this.polygonMap) {
      this.remove(this.polygonMap[code])
    }
    this.polygonMap = {}
  }

  /**
   * 编辑多边形
   * @param polygonId
   */
  editPolygon(polygonId) {
    if (this.PolygonEditor) {
      return
    }
    if (this.polygonMap[polygonId]) {
      this.PolygonEditor = new this.AMap.PolygonEditor(
        this.map,
        this.polygonMap[polygonId]
      )
      this.PolygonEditor.open()
    }
  }

  /**
   * 停止编辑
   */
  stopEditPolygon() {
    if (this.PolygonEditor) {
      this.PolygonEditor.close()
      this.PolygonEditor = null
    }
  }
}

export default AMapPlugin
