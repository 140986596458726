// 常用配置

// 菜单默认配置
export const defaultSettings = {
  title: 'Shield盾牌风控',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
}
