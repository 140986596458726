export default [
    {
      path: '/packageManagement/user/versionManagement',
      name: 'versionManagement',
      component: () => import('@/views/packageManagement/user/versionManagement/list/index'),
      meta: { title: '版本管理', keepAlive: true},
    },
    {
      path: '/packageManagement/user/versionManagement/creat',
      name: 'versionManagementCreat',
      component: () => import('@/views/packageManagement/user/versionManagement/creat/index'),
      meta: { title: '新建应用版本' },
    },
    {
      path: '/packageManagement/user/versionManagement/details',
      name: 'versionManagementDetails',
      component: () => import('@/views/packageManagement/user/versionManagement/creat/index'),
      meta: { title: '应用版本详情' },
    },
    {
      path: '/packageManagement/user/versionManagement/edit',
      name: 'versionManagementEdit',
      component: () => import('@/views/packageManagement/user/versionManagement/creat/index'),
      meta: { title: '编辑应用版本' },
    },
    {
      path: '/packageManagement/user/updatePolicy',
      name: 'updatePolicy',
      component: () => import('@/views/packageManagement/user/updatePolicy/list/index'),
      meta: { title: '更新策略', keepAlive: true },
    },
    {
      path: '/packageManagement/user/updatePolicy/creat',
      name: 'updatePolicyCreat',
      component: () => import('@/views/packageManagement/user/updatePolicy/creat/index'),
      meta: { title: '新增更新策略' },
    },
    {
      path: '/packageManagement/user/updatePolicy/details',
      name: 'updatePolicyDetails',
      component: () => import('@/views/packageManagement/user/updatePolicy/creat/index'),
      meta: { title: '更新策略详情' },
    },
    {
      path: '/packageManagement/user/updatePolicy/edit',
      name: 'updatePolicyEdit',
      component: () => import('@/views/packageManagement/user/updatePolicy/creat/index'),
      meta: { title: '编辑更新策略' },
    },
    {
      path: '/packageManagement/user/channelsPackage',
      name: 'channelsPackage',
      component: () => import('@/views/packageManagement/user/channelsPackage/list/index'),
      meta: { title: '渠道包管理', keepAlive: true },
    },
  ]
  