// 公共模块API，包含登录，菜单，登出的接口

import request from '@/utils/request'

const commonApi = {
  // 用户登录
  userLogin: (data) => {
    return request.init({
      url: '/system/user/login.htm',
      method: 'post',
      params: data,
    })
  },

  // 用户登出
  userLogout: (data) => {
    return request.init({
      url: '/system/user/logout.htm',
      method: 'post',
      data,
    })
  },

  // 登录页，刷新验证码，这里不能用post或get请求，会被网关拦截
  refreshCode: () => {
    return '/system/user/imgCode/generate.htm'
  },

  // 获取菜单数据
  queryMenuList: (data) => {
    return request.init({
      url: '/modules/manage/system/roleMenu/find.htm',
      method: 'get',
      params: data,
    })
  },

  // 渠道目录查询
  queryChannelListDict: (data) => {
    return request.init({
      url: '/channelManage/oa/channelCatalogue/tree',
      method: 'post',
      data,
    })
  },

  // 渠道标签类型查询
  queryChannelTypeDict: (data) => {
    return request.init({
      url: '/channelManage/oa/channelCommon/getType',
      method: 'post',
      data,
    })
  },
}

export default commonApi
