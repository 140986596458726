export default [
  {
    path: '/user-operation/coupon/group',
    name: 'grouplist',
    component: () => import('@/views/userOperation/coupon/group/list'),
    meta: { title: '券组' },
  },
  {
    path: '/user-operation/coupon/template',
    name: 'templatelist',
    component: () => import('@/views/userOperation/coupon/template/list'),
    meta: { title: '券模板', keepAlive: true },
  },
  {
    path: '/usercoupon/templatelist/created',
    name: 'created',
    hidden: true,
    component: () =>
      import(
        '@/views/userOperation/coupon/template/detail/template/detail/newcoupontemplate'
      ),
    meta: {
      title: '新建券模板',
      activeMenu: '/usercoupon/templatelist',
    },
  },
  {
    path: '/usercoupon/templatelist/edit',
    name: 'edit',
    hidden: true,
    component: () =>
      import(
        '@/views/userOperation/coupon/template/detail/template/detail/newcoupontemplate'
      ),
    meta: {
      title: '编辑券模板',
      activeMenu: '/usercoupon/templatelist',
    },
  },
  {
    path: '/usercoupon/templatelist/details',
    name: 'detailslist',
    hidden: true,
    component: () =>
      import(
        '@/views/userOperation/coupon/template/detail/template/detail/couponTemplateDetails'
      ),
    meta: {
      title: '券模板详情',
      activeMenu: '/usercoupon/templatelist',
    },
  },
]
