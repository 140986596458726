import apply from './apply'
import driverManage from './driverManage'
import userManage from './userManage'
import taskSubsidy from './taskSubsidy'
import customerServiceAudit from './customerServiceAudit'
import vehicleManagement from './vehicleManagement'
import userRecharge from './userRecharge'
import contractManagement from './contractManagement'
import orderAdmin from './orderAdmin'
import driverAudit from './driverAudit'
import userAudit from './userAudit'
import vehicleAudit from './vehicleAudit'

export default [
  ...apply,
  ...driverManage,
  ...userManage,
  ...taskSubsidy,
  ...customerServiceAudit,
  ...vehicleManagement,
  ...userRecharge,
  ...contractManagement,
  ...orderAdmin,
  ...driverAudit,
  ...userAudit,
  ...vehicleAudit,
]
