export default [
  // 司机资料审核
  {
    path: '/user-center/driver-audit/list',
    name: 'driverAuditList',
    component: () => import('@/views/userCenter/driverAudit/list/index'),
  },
  // 司机资料详情
  {
    path: '/user-center/driver-audit/detail',
    name: 'driverAuditDetail',
    component: () => import('@/views/userCenter/driverAudit/detail/index'),
  },
]
