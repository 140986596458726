export default [
  // 司机消息模板
  {
    path: '/message-management/driver-message/message-template',
    name: 'MessageTemplate',
    component: () =>
      import(
        '@/views/messageManagement/driverMessage/messageTemplate/list/index.vue'
      ),
    meta: { title: '司机消息模板', keepAlive: true },
  },
  {
    path: '/message-management/driver-message/message-template/new-template',
    name: 'Create',
    component: () =>
      import('@/views/messageManagement/components/newTemplate.vue'),
    meta: { title: '新建模板' },
  },
  {
    path: '/message-management/driver-message/message-template/template-details',
    name: 'detail',
    component: () =>
      import('@/views/messageManagement/components/templateDetails.vue'),
    meta: { title: '模板详情' },
  },

  // 用户消息模板
  {
    path: '/message-management/user-message/message-template',
    name: 'MessageTemplate',
    component: () =>
      import(
        '@/views/messageManagement/userMessage/messageTemplate/list/index.vue'
      ),
    meta: { title: '用户消息模板', keepAlive: true },
  },
  {
    path: '/message-management/user-message/message-template/new-template',
    name: 'NewPlan',
    component: () =>
      import('@/views/messageManagement/components/newTemplate.vue'),
    meta: { title: '新建计划' },
  },
  {
    path: '/message-management/user-message/message-template/template-details',
    name: 'detail',
    component: () =>
      import('@/views/messageManagement/components/templateDetails.vue'),
    meta: { title: '计划详情' },
  },
]
