<template>
  <div class="aside-wrap flex">
    <ul class="menu-l1">
      <li
        class="item-l1 flex flex-v flex-align-center flex-justify-center"
        :class="{ on: menuIndex === index }"
        v-for="(menu, index) in menuTree"
        :key="index"
        @click="menuIndex = index"
      >
        <img v-if="menu.menu_icon" :src="menu.menu_icon" />
        <img
          v-if="!menu.menu_icon"
          src="../../../../assets/images/layout/placeholder.png"
        />
        <div class="l1-menu-name">{{ menu.menu_name }}</div>
      </li>
    </ul>
    <el-menu
      class="menu-l2 flex-1"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
      ref="menuL2"
      :default-active="menuActive"
    >
      <template v-for="menuL2 in menuL2List">
        <el-menu-item
          @click="onClick(menuL2)"
          :key="menuL2.menu_url"
          :index="menuL2.menu_url"
          v-if="menuL2.son.length === 0"
        >
          {{ menuL2.menu_name }}
        </el-menu-item>
        <el-submenu
          v-if="menuL2.son.length > 0"
          :index="menuL2.menu_url"
          :key="menuL2.menu_url"
        >
          <template slot="title">
            <span>{{ menuL2.menu_name }}</span>
          </template>
          <el-menu-item
            v-for="menuL3 in menuL2.son"
            :index="menuL3.menu_url"
            :key="menuL3.menu_url"
            @click="onClick(menuL3)"
          >
            {{ menuL3.menu_name }}
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'LayoutAside',
  data() {
    return {
      menuIndex: 0,
      menuPathMap: {},
    }
  },
  computed: {
    menuTree() {
      let tree = this.$store.state.main.menuTree
      tree.forEach((e, i) => {
        this.completeUrl(e, i)
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.menuPathMap = Object.assign({}, this.menuPathMap) // 更新menuTree的时候，要更新菜单menuPathMap
      // console.log(tree)
      return tree
    },
    menuL2List() {
      if (!this.menuTree || !this.menuTree[this.menuIndex]) {
        return []
      }
      return this.menuTree[this.menuIndex].son ?? []
    },
    menuActive() {
      // 根据当前路由，匹配选中的菜单
      let path = this.$route.path
      let arr = path.split('/')
      let activePath = ''
      for (let i = arr.length; i > 1; i--) {
        activePath = arr.slice(0, i).join('/')
        if (this.menuPathMap[activePath] !== undefined) {
          // 匹配到菜单，同时设置api的menu_id参数
          request.setMenuId(this.menuPathMap[activePath])
          break
        }
      }
      return activePath
    },
  },
  watch: {
    menuL2List(value) {
      setTimeout(() => {
        for (let item of value) {
          if (item.son.length > 0) {
            this.$refs.menuL2.open(item.menu_url)
          }
        }
      }, 100)
    },
  },
  methods: {
    // 用最后一级的url补全上级菜单的url
    completeUrl(node, index) {
      // 参数错误
      if (!node) {
        return
      }
      // 叶子结点,返回menu_url
      if (node.son && node.son.length === 0) {
        let path = this.$route.path
        // 匹配根菜单的menuIndex
        if (path === node.menu_url) {
          this.menuIndex = index
        }
        this.menuPathMap[node.menu_url] = node.id
        return node.menu_url
      }
      // 遍历子节点
      let childUrl = '' // 其中一个子节点的url
      for (let child of node.son) {
        childUrl = this.completeUrl(child, index)
      }
      // 去掉子节点的最后一级，作为自己的url
      node.menu_url = childUrl.replace(/\/[^/]+$/, '')
      this.menuPathMap[node.menu_url] = node.id
      return node.menu_url
    },
    onClick(node) {
      if (node.menu_url !== this.$route.path) {
        this.$router.push(node.menu_url)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.aside-wrap {
  width: 100%;
  height: 100%;
  color: #bfcbd9;
  font-size: 12px;
}
.menu-l1 {
  width: 60px;
  background-color: #1f2d3d;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.menu-l1::-webkit-scrollbar {
  display: none;
}
.menu-l2 {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.menu-l2::-webkit-scrollbar {
  display: none;
}
.item-l1 {
  width: 60px;
  height: 65px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background: #304156 !important;
  }
  &.on {
    background-color: #304156 !important;
  }
  img {
    display: block;
    width: 22px;
    height: 22px;
    margin-bottom: 5px;
  }
}
</style>
