export default [
  // 补贴预算配置
  {
    path: '/user-center/taskSubsidy/budget-allocation',
    name: 'BudgetAllocation',
    component: () =>
      import('@/views/userCenter/taskSubsidy/budgetAllocation/list'),
  },

  // 补贴明细
  {
    path: '/user-center/taskSubsidy/details-of-subsidy',
    name: 'DetailsOfSubsidy',
    component: () =>
      import('@/views/userCenter/taskSubsidy/detailsOfSubsidy/list'),
  },

  // 委派面板
  {
    path: '/user-center/taskSubsidy/appointed-panel',
    name: 'AppointedPanel',
    component: () =>
      import('@/views/userCenter/taskSubsidy/appointedPanel/list'),
    meta: { keepAlive: true },
  },
]
