import withdraw from './withdraw'
import mailInvoice from './mailInvoice'
import receiptManage from './receiptManage'
import electronicInvoice from './electronicInvoice'
import remitMoney from './remitMoney'
import flowManagement from './flowManagement'
import cashPledge from '@/router/finance/cashPledge'

export default [
  ...withdraw,
  ...mailInvoice,
  ...receiptManage,
  ...electronicInvoice,
  ...remitMoney,
  ...flowManagement,
  ...cashPledge,
]
