// 开票申请
export default [
  {
    path: '/finance/receipt/receiptManage',
    name: 'receiptManage',
    component: () =>
      import('@/views/finance/receipt/receiptManage/list/index.vue'),
    meta: { title: '开票申请列表', keepAlive: true },
  },
  {
    path: '/finance/receipt/receiptManage/detail',
    name: 'detail',
    component: () =>
      import('@/views/finance/receipt/receiptManage/detail/index.vue'),
    meta: { title: '发票详情' },
  },
]
