import commonApi from '@/api/driverRegister/common'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
// import store from "@/store";

// const getDefaultState = () => {
//   return {
//     token: getToken(),
//     name: 'admin',
//     id: undefined,
//     avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
//     roles: [],
//     userInfo: null,
//   }
// }

const user = {
  state: {
    id: undefined,
    user: '',
    status: '',
    code: '',
    login: getToken() || '0',
    name: '管理员',
    avatar: '',
    introduction: '',
    roles: [],
    userInfo: null,
    orgList: [],
    selectOrgId: null,
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_LOGIN: (state, login) => {
      state.login = login
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ORGLIST: (state, orgList) => {
      state.orgList = orgList
    },
    SET_SELECTORGID: (state, selectOrgId) => {
      state.selectOrgId = selectOrgId
    },
    SET_PREPARECONFIG: (state, { index, val }) => {
      state.orgList[index].prepareConfig = val
    }
  },

  actions: {
    // user login
    login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        commonApi
          .userLogin(userInfo)
          .then(response => {
            if (response.code === 200) {
              commit('SET_LOGIN', '1')
              setToken('1')
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    // GetUserInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     getUserInfo(1).then(response => {
    //       if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
    //         reject('error')
    //       }
    //       const data = response.data
    //       const roles = ['admin'] // 目前先写死为超级管理员
    //       commit('SET_USERINFO', data)
    //       commit('SET_ROLES', roles)
    //       // if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //       //   commit('SET_ROLES', data.roles)
    //       // } else {
    //       //   reject('getInfo: roles must be a non-null array !')
    //       // }
    //       commit('SET_ID', data.id)
    //       commit('SET_NAME', data.userName)
    //       commit('SET_AVATAR', data.avatar || 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
    //       commit('SET_INTRODUCTION', data.realname)
    //       resolve(response)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    menuListnoButton ({ commit }) {
      return new Promise((resolve, reject) => {
        const param = {
          sysType: 1
        }
        commonApi
          .queryMenuList(param)
          .then(response => {
            if (!response.data) {
              // 由于mockjs 不支持自定义状态码只能这样hack
              reject('error')
            }
            const data = response.data
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // user logout
    logout ({ commit, state }) {
      // location.reload()
      // removeToken() // must remove  token  first
      // resetRouter()
      // commit('RESET_STATE')
      return new Promise((resolve, reject) => {
        commonApi
          .userLogout(state.token)
          .then(() => {
            removeToken() // must remove  token  first
            resetRouter()
            // commit('RESET_STATE')
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        removeToken() // must remove  token  first
        // commit('RESET_STATE')
        resolve()
      })
    }
  }
}

export default user
