export default {
  namespaced: true,

  state: {
    formContent: null,
    pending: null,
  },

  mutations: {
    get_fomt_content(state, id) {
      state.formContent = id
    },
    get_pending(state, id) {
      state.pending = id
    },
  },

  actions: {
    setfomtContent({ commit }, id) {
      commit('get_fomt_content', id)
    },
    setpending({ commit }, id) {
      commit('get_pending', id)
    },
  },
}
