export default [
  {
    path: '/risk-control-center/receipt_audit/list',
    component: () => import('@/views/riskControlCenter/receiptAudit/list'),
    meta: { title: '回单审核列表', keepAlive: true },
  },
  {
    path: '/risk-control-center/receipt_audit/list/detail',
    component: () => import('@/views/riskControlCenter/receiptAudit/detail'),
    meta: { title: '回单审核详情' },
  },
]
