import commonApi from '@/api/driverRegister/common'

const dict = {
  namespaced: true,

  state: {
    cityListDict: [],
    carTypeDict: [],
    channelDict: [],
    applyStateDict: [],
    trainStateDict: [],
    driverLabelDict: []
  },

  mutations: {
    SET_CITYLISTDICT: (state, cityListDict) => {
      state.cityListDict = cityListDict
    },
    SET_CARTYPEDICT: (state, carTypeDict) => {
      state.carTypeDict = carTypeDict
    },
    SET_CHANNELDICT: (state, channelDict) => {
      state.channelDict = channelDict
    },
    SET_APPLYSTATEDICT: (state, applyStateDict) => {
      state.applyStateDict = applyStateDict
    },
    SET_TRAINSTATEDICT: (state, trainStateDict) => {
      state.trainStateDict = trainStateDict
    },
    SET_DRIVERLABELDICT: (state, driverLabelDict) => {
      state.driverLabelDict = driverLabelDict
    }
  },

  actions: {
    // 获取常用城市，先取缓存，缓存没有，接口请求数据
    getCityListDict ({ commit, state }) {
      if (state.cityListDict.length === 0) {
        commonApi.queryCityList().then(response => {
          if (response.code === 0) {
            commit('SET_CITYLISTDICT', response.data.tree)
          }
        })
      }
    },
    // 获取车型车长
    getCarTypeDict ({ commit, state }) {
      if (state.carTypeDict.length === 0) {
        commonApi.queryCarTypeList().then(response => {
          if (response.code === 0) {
            commit('SET_CARTYPEDICT', response.data.tree)
          }
        })
      }
    },

    // 获取渠道来源
    getChannelDict ({ commit, state }) {
      if (state.channelDict.length === 0) {
        commonApi.queryChannelList().then(response => {
          if (response.code === 0) {
            commit('SET_CHANNELDICT', response.data.list)
          }
        })
      }
    },

    // 获取申请状态
    getApplyStateDict ({ commit, state }) {
      if (state.applyStateDict.length === 0) {
        commonApi.queryApplyStatusList().then(response => {
          if (response.code === 0) {
            commit('SET_APPLYSTATEDICT', response.data.list)
          }
        })
      }
    },

    // 获取培训状态
    getTrainStateDict ({ commit, state }) {
      if (state.trainStateDict.length === 0) {
        commonApi.queryTrainStatusList().then(response => {
          if (response.code === 0) {
            commit('SET_TRAINSTATEDICT', response.data.list)
          }
        })
      }
    },

    // 获取司机标签
    getDriverLabelDict ({ commit, state }) {
      if (state.driverLabelDict.length === 0) {
        commonApi.queryDriverLabelList().then(response => {
          if (response.code === 0) {
            commit('SET_DRIVERLABELDICT', response.data.tree)
          }
        })
      }
    }
  }
}

export default dict
