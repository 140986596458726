// import commonApi from '@/api/geoFence/common'
// import { getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'

const fence = {
  namespaced: true,
  state: {
    fenceAction: -1,
  },

  mutations: {
    CHANGE_ACTION: (state, value) => {
      console.log('CHANGE_ACTION', value)
      state.fenceAction = value
    },
  },

  actions: {},
}

export default fence
