import commonApi from '@/api/geoFence/common'
import storage from '@/utils/storage'

const common = {
  state: {
    fenceLabel: [
      {
        value: '0',
        label: '全选',
      },
      {
        value: '#1791fc',
        label: '蓝色(默认)',
      },
      {
        value: '#3ac2c0',
        label: '青色',
      },
      {
        value: '#67c23a',
        label: '绿色',
      },
      {
        value: '#e6e03c',
        label: '黄色',
      },
      {
        value: '#e6a23c',
        label: '橙色',
      },
      {
        value: '#f56c6c',
        label: '红色',
      },
      {
        value: '#ea6cf5',
        label: '紫红色',
      },
      {
        value: '#7f6cf5',
        label: '紫色',
      },
    ],
  },

  mutations: {},

  actions: {},
}

export default common
