export default [
  {
    path: '/finance/withdraw/list',
    name: 'withdrawApply',
    component: () => import('@/views/finance/withdraw/withdrawApply/list'),
    meta: { title: '提现申请', keepAlive: true },
  },
  {
    path: '/finance/withdraw/list/driver-withdrawal',
    name: 'driverWithdrawal',
    hidden: true,
    component: () =>
      import('@/views/finance/withdraw/withdrawApply/detail/driverWithdrawal'),
    meta: { title: '司机提现' },
  },
  {
    path: '/finance/withdraw/list/driver-withdrawal-detail',
    name: 'driverWithdrawalDetail',
    hidden: true,
    component: () =>
      import(
        '@/views/finance/withdraw/withdrawApply/detail/driverWithdrawalDetail'
      ),
    meta: { title: '司机提现详情' },
  },

  {
    path: '/finance/withdraw/list/user-withdrawal',
    name: 'userWithdrawal',
    hidden: true,
    component: () =>
      import('@/views/finance/withdraw/withdrawApply/detail/userWithdrawal'),
    meta: { title: '用户储值提现' },
  },
  {
    path: '/finance/withdraw/list/user-withdrawal-detail',
    name: 'userWithdrawalDetail',
    hidden: true,
    component: () =>
      import(
        '@/views/finance/withdraw/withdrawApply/detail/userWithdrawalDetail'
      ),
    meta: { title: '用户储值提现详情' },
  },
]
