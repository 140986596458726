import corporateAccount from './corporateAccount'
import enterpriseRecharge from './enterpriseRecharge'
import enterpriseProject from './enterpriseProject'
import collectDrivers from './collectDrivers'
import businessInfo from './businessInfo'

export default [
  ...corporateAccount,
  ...enterpriseRecharge,
  ...enterpriseProject,
  ...collectDrivers,
  ...businessInfo,
]
