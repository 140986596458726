export default [
  {
    path: '/config/city/order-vehicle',
    name: 'orderVehicle',
    component: () => import('@/views/config/orderVehicle/list/index.vue'),
    meta: { title: '订单车型配置' },
  },
  {
    path: '/config/city/order-vehicle/create',
    name: 'orderVehicleCreate',
    hidden: true,
    component: () => import('@/views/config/orderVehicle/detail'),
    meta: { title: '新建订单车型', activeMenu: '/config/orderVehicle' },
  },
  {
    path: '/config/city/order-vehicle/edit/:id',
    name: 'orderVehicleEdit',
    hidden: true,
    component: () => import('@/views/config/orderVehicle/detail'),
    meta: { title: '编辑订单车型', activeMenu: '/config/orderVehicle' },
  },
  {
    path: '/config/city/vehicle-group',
    name: 'vehicleGroup',
    component: () => import('@/views/config/vehicleGroup/list'),
    meta: { title: '车型组配置' },
    children: [],
  },
  {
    path: '/config/city/vehicle-group/detail/creat',
    name: 'vehicleGroupCreat',
    hidden: true,
    component: () => import('@/views/config/vehicleGroup/detail/NewModelGroup'),
    meta: { title: '新建车型组', activeMenu: '/config/city/vehicle-group' },
  },
  {
    path: '/config/city/vehicle-group/detail/edit',
    name: 'vehicleGroupEdit',
    hidden: true,
    component: () => import('@/views/config/vehicleGroup/detail/NewModelGroup'),
    meta: { title: '编辑车型组', activeMenu: '/config/city/vehicle-group' },
  },
  {
    path: '/config/city/vehicle-group/detail/edit-deposit',
    name: 'vehicleGroupEditDeposit',
    hidden: true,
    component: () =>
      import('@/views/config/vehicleGroup/detail/ModelGroupPrice'),
    meta: { title: '车型组配置', activeMenu: '/config/city/vehicle-group' },
  },
  {
    path: '/config/overall/push-order',
    name: 'pushSingle',
    component: () => import('@/views/config/pushOrder'),
    meta: { title: '推单模板' },
  },
  {
    path: '/config/overall/additionnal-service',
    name: 'additionalServices',
    component: () => import('@/views/config/additionalServices'),
    meta: { title: '额外服务配置' },
  },
]
