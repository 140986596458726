import request from '@/utils/request'

/**
 * 新建引导区
 * @param name 引导区名称
 * @param title 引导区标题
 * @param cityId 城市id
 * @param validDates [开始日期,结束日期]  ['2022-06-18','2022-06-18']
 * @param validTimes [[开始时间,结束时间]]  [['9:00','10:00'],['15:00','20:00']]
 * @param weight 权重 1:高；2:中；3:低
 * @param gis 组成多边形的点 [[经度,纬度]]
 * @returns {Promise<boolean>}
 */
async function addGuideArea(
  name,
  title,
  cityId,
  validDates,
  validTimes,
  weight,
  gis
) {
  let rsp = await request.init({
    url: '/operation/oa/guideAreaDriverTask/add',
    data: {
      name,
      title,
      city_id: cityId,
      valid_dates: validDates,
      valid_times: validTimes,
      weight,
      gis,
    },
  })
  if (rsp['code'] === 0) {
    return true
  } else {
    await Promise.reject(rsp['msg'])
  }
}

/**
 * 搜索引导区
 * @param cityIds 城市id数组
 * @param validTime 引导区有效时间搜索范围 [开始时间,结束时间]
 * @param keyWord 关键字【名称、编码】
 * @param areaStatus 区域状态【1:启用;2:禁用】
 * @param createTime 创建时间搜索范围 [开始时间,结束时间]
 * @param creator 创建人姓名或工号
 * @param page 页码
 * @param perPage 每页多少条
 * @returns {Promise<object>}
 */
async function searchGuideArea({
  cityId,
  areaStatus,
  keyWord,
  validTime,
  createTime,
  creator,
  page,
  perPage,
}) {
  let data = {
    page: page ?? 1,
    per_page: perPage ?? 1000,
  }
  if (cityId) {
    data['city_ids'] = [cityId]
  }
  if (keyWord) {
    data['key_word'] = keyWord
  }
  if (areaStatus) {
    data['area_status'] = areaStatus
  }
  if (validTime && validTime.length === 2) {
    data['valid_time'] = validTime
  }
  if (createTime && createTime.length === 2) {
    data['create_time'] = createTime
  }
  if (creator) {
    data['creator'] = creator
  }
  let rsp = await request.init({
    url: '/operation/oa/guideAreaDriverTask/search',
    data,
  })
  if (rsp['code'] === 0) {
    return rsp['data']
  } else {
    await Promise.reject(rsp['msg'])
  }
}

/**
 * 编辑引导区
 * @param guideAreaCode 引导区编码
 * @param data Object类型，属性:name、title、city_id、valid_dates、valid_times、weight、area_status、gis
 * @returns {Promise<boolean>}
 */
async function editGuideArea(guideAreaCode, data) {
  data['guide_area_code'] = guideAreaCode
  let rsp = await request.init({
    url: '/operation/oa/guideAreaDriverTask/edit',
    data,
  })
  if (rsp['code'] === 0) {
    return true
  } else {
    await Promise.reject(rsp['msg'])
  }
}

const guideAreaApi = {
  addGuideArea,
  searchGuideArea,
  editGuideArea,
}

export default guideAreaApi
