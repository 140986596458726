<template>
  <div class="user-wrap">
    <h3>个人信息</h3>
    <el-divider></el-divider>
    <div class="content">
      <div class="name">{{ user.fullname }}</div>
      <div class="row flex">
        <div class="label">用户名:</div>
        <div class="data">{{ user.staff_account || '' }}</div>
      </div>
      <div class="row flex">
        <div class="label">工号:</div>
        <div class="data">{{ user.staff_num || '' }}</div>
      </div>
      <div class="row flex">
        <div class="label">手机号:</div>
        <div class="data">{{ user.phone_number || '' }}</div>
      </div>
      <div class="row flex">
        <div class="label">邮箱:</div>
        <div class="data">{{ user.email_address || '' }}</div>
      </div>
      <div class="row flex">
        <div class="label">上级领导:</div>
        <div class="data">{{ user.leader_staff_code_name || '' }}</div>
      </div>
      <div class="row flex">
        <div class="label">角色:</div>
        <div class="data">
          {{ user.role_name.map((item) => item.role_name).join('、') }}
        </div>
      </div>
      <div class="row flex">
        <div class="label">部门:</div>
        <div class="data">
          {{
            user.department_name.map((item) => item.department_name).join('、')
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  computed: {
    user() {
      return this.$store.state.main.userInfo
    },
  },
}
</script>

<style scoped>
.user-wrap {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
}
.content {
  padding: 0 20px;
}
.name {
  font-size: 28px;
  margin-bottom: 30px;
}
.row {
  padding: 10px 0;
}
.label {
  flex: none;
  width: 200px;
}
.data {
  flex: 1 1 auto;
}
</style>
