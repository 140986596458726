export default [
  {
    path: '/finance/remit-money/import',
    name: 'CorporateRemittance',
    component: () => import('@/views/finance/remitMoney/import/list/index.vue'),
    meta: { title: '汇入', keepAlive: true },
  },
  {
    path: '/finance/remit-money/import/new-built',
    name: 'newCorporateRemittance',
    component: () =>
      import(
        '@/views/finance/remitMoney/import/detail/newCorporateRemittance.vue'
      ),
    meta: { title: '新建对公汇款' },
  },
  {
    path: '/finance/remit-money/import/detail',
    name: 'corporateRemittanceDetail',
    component: () =>
      import(
        '@/views/finance/remitMoney/import/detail/corporateRemittanceDetail.vue'
      ),
    meta: { title: '对公汇款详情' },
  },
  {
    path: '/finance/remit-money/import/data-import',
    name: 'dataImport',
    component: () =>
      import('@/views/finance/remitMoney/import/detail/dataImport.vue'),
    meta: { title: '导入对公汇款' },
  },

  // ------------ 汇出 -------------

  {
    path: '/finance/remit-money/remittance',
    name: 'Remittance',
    component: () =>
      import('@/views/finance/remitMoney/remittance/list/index.vue'),
    meta: { title: '汇出', keepAlive: true },
  },
  {
    path: '/finance/remit-money/remittance/rayment',
    name: 'Rayment',
    component: () =>
      import('@/views/finance/remitMoney/remittance/detail/payment.vue'),
    meta: { title: '付款' },
  },
  {
    path: '/finance/remit-money/remittance/details',
    name: 'Rayment',
    component: () =>
      import('@/views/finance/remitMoney/remittance/detail/paymentDetail.vue'),
    meta: { title: '汇出详情' },
  },
]
