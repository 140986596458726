const userCenter = {
  namespaced: true,

  state: {
    orderList: null,
    taskList: null,
    userList: null,
    formContent: null,
    driverList: null,
  },

  mutations: {
    get_order_list(state, id) {
      state.orderList = id
    },
    get_task_list(state, id) {
      state.taskList = id
    },
    get_user_list(state, id) {
      state.userList = id
    },
    get_fomt_content(state, id) {
      state.formContent = id
    },
    get_driver_list(state, id) {
      state.driverList = id
    },
  },

  actions: {
    setOrderList({ commit }, id) {
      commit('get_order_list', id)
    },
    setTaskList({ commit }, id) {
      commit('get_task_list', id)
    },
    setUserList({ commit }, id) {
      commit('get_user_list', id)
    },
    setfomtContent({ commit }, id) {
      commit('get_fomt_content', id)
    },
    setDriverList({ commit }, id) {
      commit('get_driver_list', id)
    },
  },
}

export default userCenter
