export default [
  // 司机信息认证
  {
    path: '/user-center/customer-service-audit/driver-info-certification',
    name: 'userPassword',
    component: () =>
      import(
        '@/views/userCenter/customerServiceAudit/driverInfoCertification/list/index.vue'
      ),
    meta: { title: '司机信息认证列表', keepAlive: true },
  },
  {
    path: '/user-center/customer-service-audit/driver-info-certification/info-authentication',
    name: 'userPassword',
    component: () =>
      import(
        '@/views/userCenter/customerServiceAudit/driverInfoCertification/detail/infoAuthentication.vue'
      ),
    meta: { title: '司机信息认证' },
  },
  {
    path: '/user-center/customer-service-audit/driver-info-certification/details',
    name: 'userPassword',
    component: () =>
      import(
        '@/views/userCenter/customerServiceAudit/driverInfoCertification/detail/certificationDetails.vue'
      ),
    meta: { title: '司机信息认证详情' },
  },

  // 车辆认证
  {
    path: '/user-center/customer-service-audit/vehicle-certification',
    name: 'vehicleCertification',
    component: () =>
      import(
        '@/views/userCenter/customerServiceAudit/vehicleCertification/list/index.vue'
      ),
    meta: { title: '车辆认证申请', keepAlive: true },
  },
  {
    path: '/user-center/customer-service-audit/vehicle-certification/certification',
    name: 'userPassword',
    component: () =>
      import(
        '@/views/userCenter/customerServiceAudit/vehicleCertification/detail/vehicleCertification.vue'
      ),
    meta: { title: '车辆认证' },
  },
  {
    path: '/user-center/customer-service-audit/vehicle-certification/details',
    name: 'details',
    component: () =>
      import(
        '@/views/userCenter/customerServiceAudit/vehicleCertification/detail/vehicleCertificationDetails.vue'
      ),
    meta: { title: '车辆认证详情' },
  },
]
