export default [
  {
    path: '/enterprise-operations/enterprise-recharge/recharge-plan',
    name: 'RechargePlan',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargePlan/list/index.vue'
      ),
    meta: { title: '企业充值计划', keepAlive: true },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/recharge-plan/newRecharge-plan',
    name: 'newRechargePlan',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargePlan/detail/newRechargePlan'
      ),
    meta: { title: '新建/编辑充值计划' },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/recharge-plan/recharge-plan-detail',
    name: 'rechargePlanDetail',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargePlan/detail/rechargePlanDetail'
      ),
    meta: { title: '充值计划详情' },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/recharge-plan/newRegular-recharge',
    name: 'newRegularRecharge',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargePlan/detail/newRegularRecharge'
      ),
    meta: { title: '新建/编辑常规充值' },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/recharge-plan/regular-recharge-detail',
    name: 'regularRechargeDetail',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargePlan/detail/regularRechargeDetail'
      ),
    meta: { title: '常规充值详情' },
  },

  // ---------- 企业充值订单 -----------

  {
    path: '/enterprise-operations/enterprise-recharge/recharge-order',
    name: 'RechargeOrder',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargeOrder/list/index.vue'
      ),
    meta: { title: '企业充值订单', keepAlive: true },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/recharge-order/detail',
    name: 'rechargeOrderDetails',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/rechargeOrder/detail/rechargeOrderDetails.vue'
      ),
    meta: { title: '企业充值订单详情' },
  },

  // --------- 充值奖励发放 --------

  {
    path: '/enterprise-operations/enterprise-recharge/award-distribution',
    name: 'awardDistribution',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/awardDistribution/list/index.vue'
      ),
    meta: { title: '充值奖励发放', keepAlive: true },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/award-distribution/newAwardDistribution',
    name: 'newAwardDistribution',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/awardDistribution/detail/newAwardDistribution.vue'
      ),
    meta: { title: '新建充值奖励发放' },
  },
  {
    path: '/enterprise-operations/enterprise-recharge/award-distribution/detail',
    name: 'awardDistributionDetail',
    component: () =>
      import(
        '@/views/enterpriseOperations/enterpriseRecharge/awardDistribution/detail/awardDistributionDetail.vue'
      ),
    meta: { title: '充值奖励发放详情' },
  },
]
