export default [
  // 保险模块
  {
    path: '/config/insure-configure/insure-mould',
    name: 'insureMould',
    component: () =>
      import('@/views/config/insureConfigure/insureMould/list/index.vue'),
    meta: { title: '保险模块', keepAlive: true },
  },
  {
    path: '/config/insure-configure/insure-mould/new-mould',
    name: 'newMould',
    component: () =>
      import(
        '@/views/config/insureConfigure/insureMould/detail/newInsureMould.vue'
      ),
    meta: { title: '新建保险模块' },
  },

  // 保险套餐
  {
    path: '/config/insure-configure/insure-package',
    name: 'insurePackage',
    component: () =>
      import('@/views/config/insureConfigure/insurePackage/list/index.vue'),
    meta: { title: '保险套餐', keepAlive: true },
  },
  {
    path: '/config/insure-configure/insure-package/new-Package',
    name: 'newPackage',
    component: () =>
      import(
        '@/views/config/insureConfigure/insurePackage/detail/newInsurePackage.vue'
      ),
    meta: { title: '新建保险商品' },
  },
]
