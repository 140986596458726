import mainApi from '@/api/main/mainApi'
import { Message } from 'element-ui'

const state = {
  menuTree: [],
  userInfo: {},
}
const getters = {}
const mutations = {
  setMenuTree(state, menuTree) {
    state.menuTree = menuTree
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
}
const actions = {
  /**
   * 登录
   * @param name 用户名
   * @param password 密码
   * @param google_code 谷歌验证码
   * @param autoLogin 7天自动登录
   * @param verifyCodeHash 图片验证码标识
   * @param verifyCodeTime 图片验证码过期时间
   */
  async login(
    _,
    { name, password, google_code, autoLogin, verifyCodeHash, verifyCodeTime }
  ) {
    let result = await mainApi.login(
      name,
      password,
      google_code,
      autoLogin,
      verifyCodeHash,
      verifyCodeTime
    )
    window.localStorage.setItem('api_token', result.data.token)
    return result
  },
  /**
   * 登出
   */
  async logout() {
    let result = await mainApi.logout()
    window.localStorage.removeItem('api_token')
    return result
  },
  /**
   * 修改密码
   */
  async changePassword(_, password) {
    return await mainApi.changePassword(password)
  },
  /**
   * 获取权限信息
   */
  async getMenuTree({ commit }) {
    let rsp = await mainApi.getMenuTreePermissions()
    if (rsp.code == 1001) return Message.error(rsp.msg)
    commit('setMenuTree', rsp.data)
  },
  /**
   * 获取用户信息
   */
  async getUserInfo({ commit }) {
    let rsp = await mainApi.getUserInfo()
    commit('setUserInfo', rsp)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
