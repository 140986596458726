export default [
  // 充值计划
  {
    path: '/user-center/rechargePlan/list',
    name: 'rechargePlan',
    component: () =>
      import('@/views/userCenter/userRecharge/rechargePlan/list/index'),
    meta: { title: '充值计划列表', keepAlive: true },
  },
  {
    path: '/user-center/rechargePlan/list/newRecharge-plan',
    name: 'newRechargePlan',
    component: () =>
      import(
        '@/views/userCenter/userRecharge/rechargePlan/detail/newRechargePlan'
      ),
    meta: { title: '新建/编辑充值计划' },
  },
  {
    path: '/user-center/rechargePlan/list/recharge-plan-detail',
    name: 'rechargePlanDetail',
    component: () =>
      import(
        '@/views/userCenter/userRecharge/rechargePlan/detail/rechargePlanDetail'
      ),
    meta: { title: '充值计划详情' },
  },
  {
    path: '/user-center/rechargePlan/list/newRegular-recharge',
    name: 'newRegularRecharge',
    component: () =>
      import(
        '@/views/userCenter/userRecharge/rechargePlan/detail/newRegularRecharge'
      ),
    meta: { title: '新建/编辑常规充值' },
  },
  {
    path: '/user-center/rechargePlan/list/regular-recharge-detail',
    name: 'regularRechargeDetail',
    component: () =>
      import(
        '@/views/userCenter/userRecharge/rechargePlan/detail/regularRechargeDetail'
      ),
    meta: { title: '常规充值详情' },
  },

  /*
   *充值订单
   */

  {
    path: '/user-center/rechargeOrder/list',
    name: 'rechargeOrder',
    component: () =>
      import('@/views/userCenter/userRecharge/rechargeOrder/list'),
    meta: { title: '充值订单列表', keepAlive: true },
  },
  {
    path: '/user-center/rechargeOrder/list/Detail',
    name: 'OrderDetail',
    component: () =>
      import(
        '@/views/userCenter/userRecharge/rechargeOrder/detail/orderDetail'
      ),
    meta: { title: '充值订单详情' },
  },

  /*
   *储值账户
   */

  {
    path: '/user-center/storedValueAccount/list',
    name: 'storedValueAccount',
    component: () =>
      import('@/views/userCenter/userRecharge/storedValueAccount/list'),
    meta: { title: '储值账户列表', keepAlive: true },
  },
  // {
  //   path: '/user-center/storedValueAccount/list/Detail',
  //   name: 'AccountDetails',
  //   component: () =>
  //     import(
  //       '@/views/userCenter/userRecharge/storedValueAccount/detail/accountDetails'
  //     ),
  //   meta: { title: '储值账户详情' },
  // },

  /*
   *增金账户
   */

  {
    path: '/user-center/incrementAccount/list',
    name: 'incrementAccount',
    component: () =>
      import('@/views/userCenter/userRecharge/incrementAccount/list'),
    meta: { title: '增金账户列表', keepAlive: true },
  },
  // {
  //   path: '/user-center/incrementAccount/list/Detail',
  //   name: 'AccountDetails',
  //   component: () =>
  //     import(
  //       '@/views/userCenter/userRecharge/incrementAccount/detail/accountDetails'
  //     ),
  //   meta: { title: '增金账户详情' },
  // },
]
