export default [
  {
    path: '/configure/global/service-configuration',
    name: 'serviceConfiguration',
    component: () => import('@/views/configure/serviceConfiguration/list/index.vue'),
    meta: { title: '服务配置' },
  },
  {
    path: '/configure/global/service-configuration/details',
    name: 'serviceConfigurationDetails',
    component: () => import('@/views/configure/serviceConfiguration/details/index.vue'),
    meta: { title: '服务配置详情' },
  },
  {
    path: '/configure/global/push-order',
    name: 'pushOrder',
    component: () => import('@/views/configure/pushOrder/index.vue'),
    meta: { title: '推单模板配置' },
  },
  {
    path: '/configure/global/laboratory',
    name: 'laboratory',
    component: () => import('@/views/configure/laboratory/index.vue'),
    meta: { title: '实验室' },
  },
  {
    path: '/configure/global/businessConfiguration',
    name: 'businessConfiguration',
    component: () => import('@/views/configure/businessConfiguration/index.vue'),
    meta: { title: '业务配置' },
  },
]
