export default [
  {
    path: '/staff-center/permission/module',
    component: () =>
      import('@/views/staffCenter/permission/menuConfiguration/index.vue'),
    meta: { title: '模块', keepAlive: true },
  },
  {
    path: '/staff-center/permission/port',
    component: () =>
      import('@/views/staffCenter/permission/operationConfiguration/index.vue'),
    meta: { title: '接口', keepAlive: true },
  },
]
