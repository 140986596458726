export default [
  {
    path: '/user-center/user-manage/theOrderList',
    name: 'theOrderList',
    component: () => import('@/views/userCenter/userManage/theOrderList/list'),
    meta: { title: '订单列表', keepAlive: true },
  },
  {
    path: '/user-center/user-manage/theOrderList/theOrderDetails',
    name: 'theOrderDetails',
    hidden: true,
    component: () =>
      import(
        '@/views/userCenter/userManage/theOrderList/detail/theOrderDetails'
      ),
    meta: {
      title: '订单详情',
      activeMenu: '/userManagement/theOrderList',
    },
  },
  {
    path: '/user-center/user-manage/userList',
    name: 'UserList',
    component: () => import('@/views/userCenter/userManage/userList/list'),
    meta: { title: '用户列表', keepAlive: true },
  },
  {
    path: '/user-center/user-manage/userList/theUserDetails',
    name: 'theUserDetails',
    hidden: true,
    component: () =>
      import('@/views/userCenter/userManage/userList/detail/theUserDetails'),
    meta: { title: '用户详情', keepAlive: true },
  },

  // 提现详情
  {
    path: '/user-center/user-manage/userList/details/withdrawalDetails',
    name: 'withdrawalDetails',
    component: () =>
      import(
        '@/views/userCenter/userManage/userList/components/withdrawalRecord/detail/withdrawalDetails.vue'
      ),
    meta: { title: '提现详情' },
  },
  {
    path: '/user-center/user-manage/userList/details/withdrawalApply',
    name: 'withdrawalApply',
    component: () =>
      import(
        '@/views/userCenter/userManage/userList/components/withdrawalRecord/detail/withdrawalApply.vue'
      ),
    meta: { title: '提现申请' },
  },
]
