export default [
  {
    path: '/market/user-channel/list',
    name: 'ref',
    component: () => import('@/views/market/userChannel/ref/index'),
    meta: { title: '渠道管理', icon: 'channel' },
  },
  {
    path: '/market/user-channel/source',
    name: 'source',
    component: () => import('@/views/market/userChannel/source/index'),
    meta: { title: '来源管理', icon: 'list' },
  },
  {
    path: '/market/user-channel/directory',
    name: 'directory',
    component: () => import('@/views/market/userChannel/directory/index'),
    meta: { title: '目录管理', icon: 'tree' },
  },
]
