<template>
  <div class="login-wrap flex flex-justify-center flex-align-center">
    <div class="login-box flex flex-v flex-align-center">
      <div class="activate_account" v-if="state == 1">
        <el-tooltip
          class="item"
          effect="dark"
          content="首次登录或重置动态验证器后，请重新激活账号"
          placement="top-start"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
        <span @click="state = 2">激活账号</span>
      </div>
      <div class="activate_account" v-if="state == 2" @click="state = 1">
        登录账号
      </div>
      <img class="logo" src="../../assets/images/layout/login-logo.png" />
      <h3 v-if="state == 1">登录账号</h3>
      <h3 v-if="state == 2">激活账号</h3>
      <el-form :model="formData" :rules="rules" ref="form">
        <el-form-item prop="name">
          <el-input v-model="formData.name" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formData.password"
            placeholder="密码"
            show-password
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item prop="google_code" v-if="state == 1">
          <div class="tips">使用基于时间的动态口令验证</div>
          <el-input
            v-model="formData.google_code"
            placeholder="动态验证码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="autoLogin" v-if="state == 1">
          <el-checkbox v-model="formData.autoLogin">7天内自动登录</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button
            class="login-btn"
            type="primary"
            @click="onLogin"
            v-if="state == 1"
            :loading="loading"
          >
            登录
          </el-button>
          <el-button
            class="login-btn"
            type="primary"
            @click="accountActivation"
            v-if="state == 2"
          >
            下一步
          </el-button>
        </el-form-item>
      </el-form>
      <div class="login_bottom">
        <el-tooltip
          class="item"
          effect="dark"
          content="请联系管理员重置密码"
          placement="bottom"
        >
          <span>忘记密码</span>
        </el-tooltip>
        |
        <el-tooltip
          class="item"
          effect="dark"
          content="请联系管理员重置动态验证器"
          placement="bottom"
        >
          <span>更换动态验证器</span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import mainApi from '@/api/main/mainApi'
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr,
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data() {
    return {
      loading: false,
      state: 1,
      // 表单数据
      formData: {
        name: '', // 用户名
        password: '', // 密码
        google_code: '', // 谷歌验证码
        autoLogin: false, // 7天自动登录
        verifyCodeHash: '', // 显示图片验证码时获得，回传给后台校验
        verifyCodeTime: '', // 显示图片验证码时获得，回传给后台校验
      },
      // 校验规则
      rules: {
        name: [{ required: true, message: '用户名不得为空', trigger: 'blur' }],
        google_code: [
          { required: true, message: '验证码不得为空', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不得为空', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {},
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      mainApi
        .getUserInfo()
        .then((res) => {
          this.$router.push('/home')
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    onLogin() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('main/login', this.formData)
            .then((rsp) => {
              this.loading = false
              // console.log(rsp)
              if (rsp.code == 0 && rsp.data.length != 0) {
                mainApi.getMenuTreePermissions().then((res) => {
                  // console.log(res)
                  if (res.code == 1001) return this.$message.error(res.msg)
                })

                this.$router.replace(this.$route.query.ref ?? '/home')
              } else {
                this.$message.error(rsp.msg)
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },

    // 激活账号
    accountActivation() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          mainApi
            .accountActivationApi(this.formData.name, this.formData.password)
            .then((res) => {
              // console.log(res)
              if (res.password_expired || res.google_secret_expired) {
                this.$router.replace({
                  path: '/change-password',
                  query: {
                    password_expired: res.password_expired,
                    google_secret_expired: res.google_secret_expired,
                  },
                })
              } else {
                this.$message({
                  message: '您的账号已激活，请通过登录账号访问后台',
                  type: 'success',
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-wrap {
  height: 100%;
  background: #f8f8f8;
}
.login-box {
  width: 500px;
  border: 1px solid #ddd;
  box-shadow: 0 5px 10px #ddd;
  background: #fff;
  border-radius: 5px;
  padding: 20px 30px 20px;

  h3 {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
}
.el-form {
  width: 100%;
}

.activate_account {
  width: 100%;
  text-align: right;
  color: #479efd;
  font-size: 15px;
  .el-icon-question {
    color: #888;
  }
}
.logo {
  display: block;
  width: 200px;
  margin-top: 15px;
  margin-bottom: 40px;
}

.tips {
  color: #888;
}
.code-img {
  width: 130px;
  height: 40px;
  margin-left: 5px;
  cursor: pointer;
  img {
    width: 100%;
  }
}
.login-btn {
  width: 100%;
}

.el-icon-success {
  color: #69c243;
}

.google_code_list {
  display: flex;
  justify-content: space-between;

  .google_code {
    text-align: center;
    .qr_code {
      margin: 10px 0;
      width: 150px;
      height: 150px;
      background-color: pink;
    }
  }
}

.login_bottom {
  color: #666;
  font-size: 14px;
}
</style>
