export default [
  // 司机通知栏消息计划
  {
    path: '/message-management/driver-message/notice-board-message',
    name: 'Push',
    component: () =>
      import(
        '@/views/messageManagement/driverMessage/noticeBoardMessage/list/index.vue'
      ),
    meta: { title: '司机通知栏消息计划', keepAlive: true },
  },
  {
    path: '/message-management/driver-message/notice-board-message/newPlan',
    name: 'NewPlan',
    component: () => import('@/views/messageManagement/components/newPlan.vue'),
    meta: { title: '新建计划' },
  },
  {
    path: '/message-management/driver-message/notice-board-message/planDetails',
    name: 'detail',
    component: () =>
      import('@/views/messageManagement/components/planDetails.vue'),
    meta: { title: '计划详情' },
  },

  // 用户通知栏消息计划
  {
    path: '/message-management/user-message/notice-board-message',
    name: 'Push',
    component: () =>
      import(
        '@/views/messageManagement/userMessage/noticeBoardMessage/list/index.vue'
      ),
    meta: { title: '用户通知栏消息计划', keepAlive: true },
  },
  {
    path: '/message-management/user-message/notice-board-message/newPlan',
    name: 'NewPlan',
    component: () => import('@/views/messageManagement/components/newPlan.vue'),
    meta: { title: '新建计划' },
  },
  {
    path: '/message-management/user-message/notice-board-message/planDetails',
    name: 'detail',
    component: () =>
      import('@/views/messageManagement/components/planDetails.vue'),
    meta: { title: '计划详情' },
  },
]
