import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import main from './modules/main/main'
import jurisdiction from './modules/staffCenter/jurisdiction'
import order from './modules/order/index'
import driverOperation from './modules/driverOperation/index'
import app from './modules/driverRegister/app'
import permission from './modules/driverRegister/permission'
import settings from './modules/driverRegister/settings'
import user from './modules/driverRegister/user'
import dict from './modules/driverRegister/dict'
import userCenter from './modules/userCenter/order'
import channel from './modules/market/channel/dict'
import finance from './modules/finance/order'
import fence from './modules/order/fence'
import common from './modules/common/common'
import configure from './modules/configure/index'
import fenceLabel from './modules/order/common'
import enterpriseOperations from './modules/enterpriseOperations/index'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    jurisdiction,
    ...order,
    ...driverOperation,
    app,
    permission,
    settings,
    user,
    dict,
    userCenter,
    channel,
    finance,
    fence,
    common,
    fenceLabel,
    ...configure,
    ...enterpriseOperations,
  },
  getters,
})
