import commonApi from '@/api/channel/common'

export default {
  namespaced: true,

  state: {
    channelDirectoryDict: [],
    channelTpyeDict: [],
  },

  mutations: {
    SET_CHANNELDIRECTORYDICT: (state, channelDirectoryDict) => {
      state.channelDirectoryDict = channelDirectoryDict
    },
    SET_CHANNELTYPEDICT: (state, channelTpyeDict) => {
      state.channelTpyeDict = channelTpyeDict
    },
  },

  actions: {
    // 获取目录，先取缓存，缓存没有，接口请求数据
    queryChannelListDict({ commit, state }) {
      if (state.channelDirectoryDict.length === 0) {
        commonApi
          .queryChannelListDict({
            type: 2,
          })
          .then((response) => {
            console.log(response)
            if (response.code === 0) {
              commit('SET_CHANNELDIRECTORYDICT', response.data)
            }
          })
      }
    },
    queryChannelTypeDict({ commit, state }) {
      if (state.channelTpyeDict.length === 0) {
        commonApi.queryChannelTypeDict().then((response) => {
          if (response.code === 0) {
            commit('SET_CHANNELTYPEDICT', response.data)
          }
        })
      }
    },
    // 重置目录
    resetChannelListDict({ commit }) {
      commit('SET_CHANNELDIRECTORYDICT', [])
    },
  },
}
