import request from '@/utils/request'

const mainApi = {
  /**
   * 登录
   * @param name 用户名
   * @param password 密码
   * @param google_code 验证码
   * @param autoLogin 自动登录
   * @param verifyCodeHash 图片验证码标识
   * @param verifyCodeTime 图片验证码过期时间
   */
  async login(
    name,
    password,
    google_code,
    autoLogin,
    verifyCodeHash,
    verifyCodeTime
  ) {
    let rsp = await request.init({
      url: '/oa/account/login/loginPassWrod',
      data: {
        staff_account: name,
        login_password: password,
        google_code,
        remember: autoLogin ? '2' : '1',
        verify_code_hash: verifyCodeHash,
        verify_code_time: verifyCodeTime,
      },
    })
    if (rsp.code === 0) {
      return rsp
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * OA登陆-账号密码激活账号
   */
  async accountActivationApi(name, password) {
    let rsp = await request.init({
      url: '/oa/account/login/accountActivation',
      data: {
        staff_account: name,
        login_password: password,
      },
    })
    if (rsp.code === 0) {
      window.localStorage.setItem('api_token', rsp.data.token)
      return rsp.data
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * 获取图片验证码
   */
  async getImageValidation() {
    let rsp = await request.init({
      url: '/oa/account/login/getImageValidation',
    })
    if (rsp.code === 0) {
      return rsp.data
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * 获取菜单树和权限
   */
  async getMenuTreePermissions(token) {
    let rsp = await request.init({
      url: '/oa/organize/menu/getMenuTreePermissions',
      data: {
        token,
      },
    })
    return rsp
  },

  /**
   * 修改密码
   * @param password
   * @returns {Promise<boolean>}
   */
  async changePassword(password) {
    let rsp = await request.init({
      url: '/oa/organize/staff/updatePassword',
      data: {
        password,
      },
    })
    if (rsp.code === 0) {
      return true
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * 获取用户信息
   */
  async getUserInfo() {
    let rsp = await request.init({
      url: '/oa/organize/staff/getDetailsStaffByToken',
    })
    if (rsp.code === 0) {
      return rsp.data
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * 退出登录
   */
  async logout() {
    let rsp = await request.init({
      url: '/oa/organize/staff/exitLogin',
    })
    if (rsp.code === 0) {
      return rsp.data
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * OA登陆-验证google code
   */
  async verifyGoogleCode(data) {
    let rsp = await request.init({
      url: '/oa/account/login/verifyGoogleCode',
      data,
    })
    if (rsp.code === 0) {
      return rsp.data
    } else {
      throw new Error(rsp.msg)
    }
  },

  /**
   * OA登陆-获取google验证码url
   */
  async getGoogleQrcodeUrlApi() {
    let rsp = await request.init({
      url: '/oa/account/login/getGoogleQrcodeUrl',
    })
    if (rsp.code === 0) {
      return rsp.data
    } else {
      throw new Error(rsp.msg)
    }
  },
}

export default mainApi
